/* eslint-disable @mate-academy/frontend/restrict-window-usage, @mate-academy/frontend/restrict-error-throwing */
import cookie from 'cookie';
import getConfig from 'next/config';
import sbjs from '@mate-academy/sourcebuster';
import { UtmTags } from '@/controllers/graphql/generated';
import { errorHandler } from '@/core/ErrorHandler';
import { logger } from '@/core/Logger';
import { Router } from '@/middleware/i18n';
import {
  USER_ANALYTICS_ID_KEY_NAME,
  USER_DEVICE_ID_KEY_NAME,
  USER_SESSION_ID_KEY_NAME,
  USER_SESSION_ID_MAX_AGE,
} from '@/controllers/analytics/analytics.constants';
import {
  removeQueryParams,
} from '@/controllers/analytics/analytics.utils/removeQueryParams';
import { NodeEnvironments } from '@/lib/constants/general';
import {
  i18nExtractSubDomainFromPath,
} from '@/middleware/i18n/i18n.utils';
import { Products } from '@/constants/products';
import { UserAnalyticsFragment } from '@/controllers/user/graphql/generated/UserAnalytics.fragment.generated';
import { UserTrackingInfoFragment } from '@/controllers/user/graphql/generated/UserTrackingInfo.fragment.generated';
import { SubDomains } from '@/constants/subdomains';

export class GoogleAnalyticsClient {
  private readonly productName: Products = Products.Mate;

  private domain: string;

  private readonly sourceTracker: any;

  private readonly googleTracker: typeof window.dataLayer;

  private static instance: GoogleAnalyticsClient;

  private authUser?: UserAnalyticsFragment | null;

  private analyticsDeviceId?: string;

  private analyticsUserId: string | null = null;

  private logger = logger.child('GoogleAnalyticsClient');

  private EVENTS_BLOCK_LIST = new Set([
    'gtm.click',
    'gtm.formInteract',
    'gtm.dom',
    'gtm.formSubmit',
    'gtm.historyChange',
    'gtm.historyChange-v2',
    'gtm.js',
    'gtm.linkClick',
    'gtm.load',
    'gtm.scrollDepth',
    'gtm.video',
    'optimize.activate',
    'cookie_consent_marketing',
    'cookie_consent_preferences',
    'cookie_consent_statistics',
    'cookie_consent_update',
  ]);

  private EVENTS_PATTERN_BLOCK_LIST = [
    'optimize.activate',
  ];

  constructor(productName: Products = Products.Mate) {
    this.googleTracker = window.dataLayer;
    this.sourceTracker = sbjs;
    this.productName = productName;

    this.domain = this.productName === Products.Mate
      ? 'mate.academy'
      : 'knowely.com';

    this.init();
  }

  init() {
    this.initSourceTracker();
    this.initGoogleTracker();
    this.initPageVisitTracker();
  }

  private static base64Id() {
    const base64Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

    let str = '';

    for (let i = 0; i < 22; i += 1) {
      str += base64Chars.charAt(Math.floor(Math.random() * 64));
    }

    return str;
  }

  private getEnv() {
    const { publicRuntimeConfig } = getConfig();

    return publicRuntimeConfig.NODE_ENV;
  }

  private getEndpoint() {
    const { publicRuntimeConfig } = getConfig();

    return publicRuntimeConfig.MATE_ANALYTICS_ENDPOINT;
  }

  private initSourceTracker() {
    if (this.getEnv() === NodeEnvironments.Test) {
      this.domain = 'localhost';
    }

    this.sourceTracker.init({
      domain: this.domain,
      user_ip: this.getUserIP(),
    });

    window.sbjs = this.sourceTracker;
  }

  private initGoogleTracker() {
    const { publicRuntimeConfig } = getConfig();
    const env = this.getEnv();
    const isGTMLoaded = this.googleTracker.push === Array.prototype.push;

    if (publicRuntimeConfig.ANALYTICS_ENABLED !== 'true') {
      return;
    }

    if (isGTMLoaded && env !== NodeEnvironments.Test) {
      // do not init until gtm is loaded
      setTimeout(() => {
        this.initGoogleTracker();
      }, 100);

      return;
    }

    const originalPush = this.googleTracker.push;

    this.googleTracker.forEach(this.processInternalEvent);

    this.googleTracker.push = (...items) => {
      items.forEach((item) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { gtm, ...rest } = item;

        this.processInternalEvent(rest);
      });

      try {
        return originalPush(...(items.map((item) => {
          const { gtm = {}, ...rest } = item;

          return {
            ...rest,
            ...gtm,
          };
        })));
      } catch (error) {
        // If a user has enabled the Ads Blocker, GTM can't be initialized
        return 0;
      }
    };
  }

  private initPageVisitTracker() {
    const sendEvent = () => {
      this.processInternalEvent({
        event: 'page_visit',
        page: removeQueryParams(Router.asPath),
        locale: Router.locale,
      });
    };

    sendEvent();

    Router.events.on('routeChangeComplete', () => {
      sendEvent();
    });
  }

  private safeStringify(obj: Record<string, any>) {
    const cache: any[] = [];

    return JSON.stringify(
      obj,
      (key, value) => {
        if (key === 'gtm.element') {
          return null;
        }

        // eslint-disable-next-line no-nested-ternary
        return typeof value === 'object' && value !== null
          ? cache.includes(value)
            ? undefined // Duplicate reference found, discard key
            : cache.push(value) && value // Store value in our collection
          : value;
      },
    );
  }

  processInternalEvent = (item: any) => {
    if (!item.event) {
      return;
    }

    if (
      this.EVENTS_BLOCK_LIST.has(item.event)
      || this.EVENTS_PATTERN_BLOCK_LIST.some(
        (pattern) => item.event.includes(pattern),
      )
    ) {
      return;
    }

    const { sessionId, isNewSession } = this.ensureSessionId();

    if (sessionId && isNewSession) {
      this.sendInternalEvent({
        event: 'session_start',
        sessionId,
      });
    }

    this.sendInternalEvent({
      ...item,
      sessionId,
    });
  };

  setUser(user?: UserAnalyticsFragment | null) {
    this.authUser = user;
    this.analyticsUserId = null;

    this.setGoogleUserId();
  }

  private sendInternalEvent(item: any) {
    const { event, sessionId, ...properties } = item;

    const subDomain = i18nExtractSubDomainFromPath({
      url: window.location.pathname,
    });

    const forcedSubDomain = this.productName === Products.Knowely
      ? SubDomains.us
      : subDomain;

    const sourceData = this.getUserSourceData();

    const payload = {
      event_type: event,
      location: window.location.pathname,
      // time: new Date().toISOString(), // server will set this
      event_properties: {
        subDomain: forcedSubDomain,
        brand: this.domain,
        ...properties,
      },
      ...sourceData,
      session_id: sessionId,
      platform_user_id: this.getAnalyticsUserId(),
      platform_device_id: this.getAnalyticsDeviceId(),
    };

    const result = this.safeStringify(payload);

    const headers = {
      'Content-Type': 'application/json',
    };

    const canSendViaBeacon = (
      typeof navigator !== 'undefined' && Boolean(navigator.sendBeacon)
    );

    const env = this.getEnv();

    if (canSendViaBeacon && env !== NodeEnvironments.Test) {
      try {
        const data = new Blob([result], { type: 'application/json' });

        const isScheduled = navigator.sendBeacon(
          this.getEndpoint(),
          data,
        );

        if (!isScheduled) {
          throw new Error('scheduling failed');
        } else {
          return;
        }
      } catch (error) {
        this.logger.child('processInternalEvent').info(`failed to send event via beacon: ${error.message}. Fall-back to fetch`, {
          endpoint: this.getEndpoint(),
          event_type: payload.event_type,
          platform_user_id: this.getAnalyticsUserId(),
          platform_device_id: this.getAnalyticsDeviceId(),
        });
      }
    }

    fetch(
      this.getEndpoint(),
      {
        method: 'POST',
        body: result,
        credentials: 'omit',
        headers,
      },
    )
      .then(async (response) => {
        if (!response.ok) {
          const responseData = await response.json();

          throw new Error(`Network response was not ok: ${response.statusText}; response: ${JSON.stringify(responseData)}`);
        }
      })
      .catch((error) => {
        errorHandler.captureException(error, {
          logMessage: 'failed to send event',
          logger: this.logger.child('processInternalEvent'),
          fields: {
            endpoint: this.getEndpoint(),
            event_type: payload.event_type,
            platform_user_id: this.getAnalyticsUserId(),
            platform_device_id: this.getAnalyticsDeviceId(),
            ...error,
          },
        });
      });
  }

  private ensureSessionId(): {
    sessionId?: string;
    isNewSession: boolean;
    } {
    const sourceTrackerSessionId = this.sourceTracker.get.session.sid;

    const currentSessionId = this.getCookieUserSessionId();

    const isSessionChanged = (
      sourceTrackerSessionId
      && currentSessionId
      && (Number(sourceTrackerSessionId) > Number(currentSessionId))
    );

    const shouldSetNewSessionId = (
      !currentSessionId
      || isSessionChanged
    );

    if (shouldSetNewSessionId) {
      const sessionId = this.setCookieUserSessionId();

      return {
        sessionId,
        isNewSession: true,
      };
    }

    this.prolongCookieUserSessionId(currentSessionId);

    return {
      sessionId: currentSessionId,
      isNewSession: false,
    };
  }

  private getStoredAnalyticsUserId() {
    let userId = '';

    try {
      userId = localStorage.getItem(USER_ANALYTICS_ID_KEY_NAME) ?? '';
    } catch (error) {
      /* silent */
    }

    return userId;
  }

  private setStoredAnalyticsUserId(id: string) {
    try {
      localStorage.setItem(USER_ANALYTICS_ID_KEY_NAME, id);
    } catch (error) {
      /* silent */
    }
  }

  private getCookieUserSessionId() {
    let sessionId = '';

    try {
      sessionId = cookie.parse(document.cookie)[USER_SESSION_ID_KEY_NAME] ?? '';
    } catch (error) {
      /* silent */
    }

    return sessionId;
  }

  private setCookieUserSessionId(): string | undefined {
    try {
      const currentTimestamp = new Date().getTime();

      document.cookie = `${USER_SESSION_ID_KEY_NAME}=${currentTimestamp}; path=/; max-age=${USER_SESSION_ID_MAX_AGE}; domain=${this.domain}`;

      return currentTimestamp.toString();
    } catch (error) {
      /* silent */
    }

    return undefined;
  }

  private prolongCookieUserSessionId(
    sessionId: string,
  ) {
    try {
      document.cookie = `${USER_SESSION_ID_KEY_NAME}=${sessionId}; path=/; max-age=${USER_SESSION_ID_MAX_AGE}; domain=${this.domain}`;
    } catch (error) {
      /* silent */
    }
  }

  private getCookieAnalyticsUserId() {
    let userId = '';

    try {
      userId = cookie.parse(document.cookie)[USER_ANALYTICS_ID_KEY_NAME] ?? '';
    } catch (error) {
      /* silent */
    }

    return userId;
  }

  private setCookieAnalyticsUserId(id: string) {
    try {
      document.cookie = `${USER_ANALYTICS_ID_KEY_NAME}=${id}; path=/; max-age=31536000; domain=${this.domain}`;
    } catch (error) {
      /* silent */
    }
  }

  private ensureAnalyticsUserId() {
    if (this.authUser) {
      const { platformAnalyticsId } = this.authUser;

      this.setStoredAnalyticsUserId(platformAnalyticsId);
      this.setCookieAnalyticsUserId(platformAnalyticsId);

      return platformAnalyticsId;
    }

    const userAnalyticsIdFromStorage = this.getStoredAnalyticsUserId();

    if (userAnalyticsIdFromStorage) {
      this.setCookieAnalyticsUserId(userAnalyticsIdFromStorage);

      return userAnalyticsIdFromStorage;
    }

    const userAnalyticsIdFromCookie = this.getCookieAnalyticsUserId();

    if (userAnalyticsIdFromCookie) {
      this.setStoredAnalyticsUserId(userAnalyticsIdFromCookie);

      return userAnalyticsIdFromCookie;
    }

    return null;
  }

  getAnalyticsUserId() {
    if (!this.analyticsUserId) {
      this.analyticsUserId = this.ensureAnalyticsUserId();
    }

    return this.analyticsUserId;
  }

  setGoogleUserId() {
    this.googleTracker.push({
      event: 'userId',
      user_id: this.getAnalyticsUserId(),
    });
  }

  getUserAgent() {
    let userAgent = 'unknown';

    try {
      userAgent = this.sourceTracker.get.udata.uag;
    } catch (error) {
      errorHandler.captureException(error as Error, {
        logger: this.logger.child('getUserAgent'),
        logMessage: 'Can`t get user agent',
      });
    }

    return userAgent;
  }

  getUserSourceData() {
    type UserTrackingInfo = Omit<UserTrackingInfoFragment, '__typename'>;
    type UtmTagsData = Omit<UtmTags, 'id' | 'action'>;

    const data: UserTrackingInfo & UtmTagsData = {
      fvType: '',
      fvSource: '',
      fvMedium: '',
      fvCampaign: '',
      fvContent: '',
      fvTerm: '',
      fvPage: '',
      lvType: '',
      lvSource: '',
      lvMedium: '',
      lvCampaign: '',
      lvContent: '',
      lvTerm: '',
      lvAccountId: '',
      lvCampaignId: '',
      lvAdGroupId: '',
      lvAdId: '',
      lvTermId: '',
      gclid: '',
      gClientid: '',
      gIp: '',
      gAgent: '',
      utmDevicetype: '',
      utmPlacement: '',
    };

    try {
      data.fvType = this.sourceTracker.get.first.typ;
      data.fvSource = this.sourceTracker.get.first.src;
      data.fvMedium = this.sourceTracker.get.first.mdm;
      data.fvCampaign = this.sourceTracker.get.first.cmp;
      data.fvContent = this.sourceTracker.get.first.cnt;
      data.fvTerm = this.sourceTracker.get.first.trm;

      data.lvType = this.sourceTracker.get.current.typ;
      data.lvSource = this.sourceTracker.get.current.src;
      data.lvMedium = this.sourceTracker.get.current.mdm;
      data.lvCampaign = this.sourceTracker.get.current.cmp;
      data.lvContent = this.sourceTracker.get.current.cnt;
      data.lvTerm = this.sourceTracker.get.current.trm;
      data.lvAccountId = this.sourceTracker.get.current.ac_id;
      data.lvCampaignId = this.sourceTracker.get.current.cmp_id;
      data.lvAdGroupId = this.sourceTracker.get.current.adg_id;
      data.lvAdId = this.sourceTracker.get.current.ad_id;
      data.lvTermId = this.sourceTracker.get.current.trm_id;

      data.fvPage = this.getUserFirstVisitPage();

      data.gclid = this.getUserUtmFromCookie('_mate-gclid');

      data.gIp = this.sourceTracker.get.udata.uip;
      data.gAgent = this.getUserAgent();
      data.gClientid = this.getGoogleClientId();

      data.utmDevicetype = this.getUserUtmFromCookie('_mate-utm_devicetype');
      data.utmPlacement = this.getUserUtmFromCookie('_mate-utm_placement');
    } catch (error) {
      errorHandler.captureException(error as Error, {
        logger: this.logger.child('getUserSourceData'),
        logMessage: 'Error getting analytics data',
      });
    }

    return data;
  }

  getUserUtmFromCookie(key: string) {
    const cookieValue = cookie.parse(document.cookie)[key];

    if (!cookieValue) {
      return undefined;
    }

    return cookieValue;
  }

  getGoogleClientId() {
    const getTrackers = window.ga?.getAll;
    const COOKIE_NAME = '_ga';

    let clientId = '0.0';

    try {
      clientId = getTrackers?.()?.[0]?.get('clientId')
        ?? cookie.parse(document.cookie)[COOKIE_NAME];
    } catch (error) {
      /* silent */
    }

    return clientId;
  }

  getUserIP() {
    let userIp = '8.8.8.8';

    try {
      userIp = cookie.parse(document.cookie)['_mate-user-ip'] ?? userIp;
    } catch (error) {
      /* silent */
    }

    return userIp;
  }

  getUserFirstVisitPage() {
    let normalizedFvPage = '';

    try {
      const userFirstVisitPageUrl = this.sourceTracker.get.first_add.ep;
      const urlObject = new URL(userFirstVisitPageUrl);

      normalizedFvPage = urlObject.pathname.slice(1);

      if (normalizedFvPage.length === 0) {
        normalizedFvPage = 'home';
      }
    } catch (error) {
      /* silent */
    }

    return normalizedFvPage;
  }

  private getStoredAnalyticsDeviceId() {
    let deviceId = '';

    try {
      deviceId = localStorage.getItem(USER_DEVICE_ID_KEY_NAME) ?? '';
    } catch (error) {
      /* silent */
    }

    return deviceId;
  }

  private setStoredAnalyticsDeviceId(id: string) {
    try {
      localStorage.setItem(USER_DEVICE_ID_KEY_NAME, id);
    } catch (error) {
      /* silent */
    }
  }

  private getCookieAnalyticsDeviceId() {
    let deviceId = '';

    try {
      deviceId = cookie.parse(document.cookie)[USER_DEVICE_ID_KEY_NAME] ?? '';
    } catch (error) {
      /* silent */
    }

    return deviceId;
  }

  private setCookieAnalyticsDeviceId(id: string) {
    try {
      document.cookie = `${USER_DEVICE_ID_KEY_NAME}=${id}; path=/; max-age=31536000; domain=${this.domain}`;
    } catch (error) {
      /* silent */
    }
  }

  private ensureAnalyticsDeviceId() {
    const cookieDeviceId = this.getCookieAnalyticsDeviceId();

    if (cookieDeviceId) {
      this.setStoredAnalyticsDeviceId(cookieDeviceId);

      return cookieDeviceId;
    }

    const storedAnalyticsDeviceId = this.getStoredAnalyticsDeviceId();

    if (storedAnalyticsDeviceId) {
      this.setCookieAnalyticsDeviceId(storedAnalyticsDeviceId);

      return storedAnalyticsDeviceId;
    }

    const deviceId = GoogleAnalyticsClient.base64Id();

    this.setStoredAnalyticsDeviceId(deviceId);
    this.setCookieAnalyticsDeviceId(deviceId);

    return deviceId;
  }

  getAnalyticsDeviceId() {
    if (!this.analyticsDeviceId) {
      this.analyticsDeviceId = this.ensureAnalyticsDeviceId();
    }

    return this.analyticsDeviceId;
  }

  static getInstance(productName: Products = Products.Mate) {
    if (!GoogleAnalyticsClient.instance) {
      GoogleAnalyticsClient.instance = new GoogleAnalyticsClient(productName);
    }

    return GoogleAnalyticsClient.instance;
  }

  sendEvent(event: string, data: Record<string, any> = {}) {
    try {
      this.googleTracker.push({
        event,
        ...data,
      });
    } catch (error) {
      errorHandler.captureException(new Error(`Couldn't send analytics event, "${(error as Error).message}"`), {
        logger: this.logger.child('sendEvent'),
        logMessage: 'Error sending event',
        fields: {
          event,
        },
      });
    }
  }
}
