import {
  SubDomainFeatureConfig,
} from '@/controllers/features/subDomainFeature.typedefs';
import { SubDomains } from '@/constants/subdomains';
import { Features } from '@/controllers/features/features.constants';

const { usa, us } = SubDomains;

export const SubDomainFeatures = {
  EnglishEventTab: Features.EnglishEventTab,
  WebinarsEventTab: Features.WebinarsEventTab,
  EnglishTab: Features.EnglishTab,
  ApplicationHistory: Features.ApplicationHistory,
  EnglishLevelInfoInProfile: Features.EnglishLevelInfoInProfile,
  MentorTaskReview: Features.MentorTaskReview,
  SoftDeadlines: Features.SoftDeadlines,
};

const CONFIG: SubDomainFeatureConfig[] = [
  {
    name: SubDomainFeatures.EnglishEventTab,
    description: 'English tab on events page',
    filter: { disabledIn: [usa] },
  },
  {
    name: SubDomainFeatures.WebinarsEventTab,
    description: 'Webinar tab on events page',
    filter: { disabledIn: [usa] },
  },
  {
    name: SubDomainFeatures.EnglishTab,
    description: 'English tab in the navigation menu',
    filter: { disabledIn: [usa] },
  },
  {
    name: SubDomainFeatures.ApplicationHistory,
    description: 'Application history button in the all courses header',
    filter: { disabledIn: [usa] },
  },
  {
    name: SubDomainFeatures.EnglishLevelInfoInProfile,
    description: 'English level info in the profile',
    filter: { disabledIn: [usa] },
  },
  {
    name: SubDomainFeatures.MentorTaskReview,
    description: 'Mentor task review for GH tasks',
    filter: { disabledIn: [us] },
  },
  {
    name: SubDomainFeatures.SoftDeadlines,
    description: 'Soft deadlines for flex students',
    filter: { disabledIn: [us] },
  },
];

const SUB_DOMAIN_FEATURES = {
  config: CONFIG,
};

export default SUB_DOMAIN_FEATURES;
