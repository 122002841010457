/* eslint-disable max-len, @typescript-eslint/no-empty-interface, no-trailing-spaces, arrow-body-style */

export enum SupportSocialNames {
  Telegram = 'TELEGRAM',
  Whatsapp = 'WHATSAPP',
  Viber = 'VIBER',
  Facebook = 'FACEBOOK'
}

export enum ParticipantRole {
  Admin = 'ADMIN',
  Student = 'STUDENT',
  Recruiter = 'RECRUITER'
}

export enum AiAssistantContext {
  BeforeFirstRun = 'BEFORE_FIRST_RUN',
  TestsFail = 'TESTS_FAIL',
  ProactiveMessageShown = 'PROACTIVE_MESSAGE_SHOWN'
}

export enum AiChatTrigger {
  LinearTaskHelpBlock = 'LINEAR_TASK_HELP_BLOCK',
  LinearFailedResult = 'LINEAR_FAILED_RESULT'
}

export enum CertificateType {
  Module = 'MODULE',
  SkillPath = 'SKILL',
  CareerPath = 'CAREER'
}

export enum ChatKind {
  Direct = 'DIRECT',
  Regular = 'REGULAR',
  Service = 'SERVICE',
  Lesson = 'LESSON'
}

export enum ChatGroup {
  Favorite = 'favourite',
  All = 'all',
  Direct = 'direct'
}

export enum GoToDirectChatSource {
  Profile = 'profile',
  ProfilePreview = 'profilePreview',
  TestTaskActivity = 'testTaskActivity'
}

export enum PracticePageChatSource {
  Video = 'video',
  Theory = 'theory',
  Practice = 'practice'
}

export enum ChatLocation {
  ChatPage = 'chatPage',
  PracticePage = 'practicePage',
  SimilarChatsTab = 'similarChatsTab'
}

export enum LinkedTestTask {
  Task = 'task',
  Other = 'other'
}

export enum UserEnglishLevel {
  Unspecified = 'UNSPECIFIED',
  Beginner = 'BEGINNER',
  LowElementary = 'LOW_ELEMENTARY',
  HighElementary = 'HIGH_ELEMENTARY',
  LowPreIntermediate = 'LOW_PRE_INTERMEDIATE',
  HighPreIntermediate = 'HIGH_PRE_INTERMEDIATE',
  LowIntermediate = 'LOW_INTERMEDIATE',
  HighIntermediate = 'HIGH_INTERMEDIATE',
  UpperIntermediate = 'UPPER_INTERMEDIATE',
  Advanced = 'ADVANCED'
}

export enum PersonalLessonsBannerAction {
  Closed = 'closed',
  CtaButtonClicked = 'cta_button_clicked'
}

export enum ExitPopUpState {
  Shown = 'shown',
  Accepted = 'accepted',
  Rejected = 'rejected'
}

export enum ExitPopUpSource {
  Quiz = 'quiz'
}

export enum ExitPopUpType {
  Modal = 'modal',
  BrowserNative = 'browser_native'
}

export enum ReApplyButtonSource {
  FailedTest = 'FAILED_TEST_ASSIGNMENT',
  FailedInterview = 'FAILED_INTERVIEW'
}

export enum GitHubReviewState {
  Approved = 'approved',
  ChangesRequested = 'changes_requested',
  Commented = 'commented',
  Dismissed = 'dismissed',
  Pending = 'pending'
}

export enum GroupDashboardTab {
  Homework = 'homework',
  MorningTasks = 'morning_tasks',
  Attendance = 'attendance',
  English = 'english',
  Typing = 'typing',
  TechCheck = 'tech_check',
  Summary = 'summary',
  AutoTechCheck = 'auto_tech_check',
  Applying = 'applying',
  FlexTechCheck = 'flex_tech_check'
}

export enum ReviewButtonType {
  ReadOriginal = 'read_original',
  LinkedIn = 'linkedin'
}

export enum ReviewButtonClickedSource {
  ReviewsPage = 'reviews-page',
  SubscriptionPage = 'subscription-page'
}

export enum CourseType {
  Prepaid = 'PREPAID',
  Postpaid = 'POSTPAID',
  Marathon = 'MARATHON',
  Webinar = 'WEBINAR',
  Consultation = 'CONSULTATION',
  Module = 'MODULE'
}

export enum CashbackAction {
  Clicked = 'clicked',
  Closed = 'closed'
}

export enum DeviceType {
  Mobile = 'mobile',
  Desktop = 'desktop'
}

export enum HireGraduateFormSource {
  Header = 'header',
  Footer = 'footer'
}

export enum LeadScore {
  TopLeadSurvey = 'top_lead_survey',
  BlankLeadSurvey = 'blank_lead_survey'
}

export enum ResetPasswordAction {
  SaveNewPassword = 'save_new_password',
  RequestPasswordReset = 'request_password_reset'
}

export enum PopupAction {
  Opened = 'opened',
  Closed = 'closed',
  Confirmed = 'confirmed'
}

export enum OpenTrialAccessStatus {
  Success = 'success',
  Forbidden = 'forbidden'
}

export enum PromoCodeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Expired = 'EXPIRED'
}

export enum PromoCodeType {
  Internal = 'INTERNAL',
  Partner = 'PARTNER'
}

export enum VideoType {
  Regular = 'REGULAR',
  Invid = 'INVID'
}

export enum MinimizedViewItem {
  Video = 'VIDEO',
  Theory = 'THEORY',
  TaskDescription = 'TASK_DESCRIPTION'
}

export enum SolutionType {
  Video = 'VIDEO',
  File = 'FILE',
  ExternalLink = 'EXTERNAL',
  NoReference = 'NO_REFERENCE'
}

export enum AppVersionOnboardingAction {
  Completed = 'COMPLETED',
  Skipped = 'SKIPPED'
}

export enum PeerReviewStatus {
  Unavailable = 'Unavailable',
  Blocked = 'Blocked',
  Pending = 'Pending',
  Reviewing = 'Reviewing',
  Done = 'Done',
  Skipped = 'Skipped'
}

export enum SubscriptionConfirmationMethods {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export enum SubscriptionRecurringPurchaseStatus {
  Accepted = 'ACCEPTED',
  Failed = 'FAILED'
}

export enum NextPaymentBlockAction {
  LinkCard = 'link_card',
  PayNow = 'pay_now'
}

export enum SubscriptionProductSources {
  Platform = 'PLATFORM',
  Landing = 'LANDING'
}

export enum PaywallLocations {
  SubscriptionPurchase = 'subscription_purchase',
  ModuleBlocked = 'module_blocked',
  TopicBlocked = 'topic_blocked',
  MySubscription = 'my_subscription',
  AutoTechCheckTemplatesList = 'auto_tech_check_templates_list',
  AutoTechChecks = 'auto_tech_checks',
  Chats = 'chats',
  DailyTasksSidebar = 'daily_tasks_sidebar',
  DailyTasks = 'daily_tasks',
  TopicContent = 'topic_content',
  UserProfile = 'user_profile',
  IntroOfferPage = 'intro_offer_page',
  PricingVariationPage = 'pricing_variation_page',
  FreeTrialPricingPage = 'free_trial_pricing_page',
  UnlockAllCourses = 'unlock_all_courses'
}

export enum DesktopLinkEmailButtons {
  Send = 'SEND',
  Skip = 'SKIP'
}

export enum DeviceTypes {
  Mobile = 'MOBILE',
  Tablet = 'TABLET',
  Desktop = 'DESKTOP'
}

export enum RegistrationType {
  Email = 'EMAIL',
  TemporaryEmail = 'TEMPORARY_EMAIL',
  Anonymous = 'ANONYMOUS'
}

export enum CheckoutPaymentProvider {
  Solidgate = 'SOLIDGATE',
  Stripe = 'STRIPE'
}

export enum RenewSubscriptionButtonLocations {
  CurrentPlan = 'current_plan',
  ChangePaymentMethod = 'change_payment_method'
}

export enum PaymentMethods {
  Card = 'card',
  ApplePay = 'apple_pay',
  GooglePay = 'google_pay',
  Paypal = 'paypal'
}

export enum TaskReviewStatus {
  PendingPeer = 'PENDING_PEER',
  ApprovedPeer = 'APPROVED_PEER',
  RejectedPeer = 'REJECTED_PEER',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  MentorReviewAgain = 'MENTOR_REVIEW_AGAIN',
  Expire = 'EXPIRE',
  OnReview = 'ON_REVIEW',
  ExpirePeer = 'EXPIRE_PEER',
  OnReviewPeer = 'ON_REVIEW_PEER',
  Canceled = 'CANCELED',
  ExpireAi = 'EXPIRE_AI',
  ApprovedAi = 'APPROVED_AI',
  RejectedAi = 'REJECTED_AI',
  OnReviewAi = 'ON_REVIEW_AI',
  PendingAi = 'PENDING_AI'
}

export enum TaskReviewType {
  Employment = 'Employment',
  Homework = 'Homework',
  TeamProject = 'TeamProject',
  Morning = 'Morning',
  Daily = 'Daily'
}

export enum AttachmentTaskLinkTypes {
  Other = 'other',
  GoogleDoc = 'google_doc',
  GoogleSheets = 'google_sheets',
  Looms = 'looms',
  Figma = 'figma'
}

export enum ReviewerRole {
  Peer = 'PEER',
  Ai = 'AI',
  Mentor = 'MENTOR'
}

export enum TeamProjectIntroSteps {
  Join = 'team_project_intro_join_step',
  WaitingTeam = 'team_project_intro_waiting_team_step',
  Start = 'team_project_intro_start_step'
}

export enum ProgrammingLanguage {
  JavaScript = 'javascript',
  TypeScript = 'typescript',
  Python = 'python',
  Java = 'java',
  Sql = 'sql',
  Markup = 'markup',
  English = 'english',
  QA = 'qa',
  Design = 'design',
  Employment = 'employment',
  Recruitment = 'recruitment',
  TeamProject = 'team_project',
  DevOps = 'devops',
  DigitalMarketing = 'digital_marketing',
  DataAnalytics = 'data_analytics'
}

export enum TestTaskStatus {
  Passed = 'PASSED',
  Failed = 'FAILED',
  NotChecked = 'NOT_CHECKED',
  MentorReview = 'MENTOR_REVIEW',
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  StudentReview = 'STUDENT_REVIEW',
  StudentReviewFailed = 'STUDENT_REVIEW_FAILED',
  StudentReviewApproved = 'STUDENT_REVIEW_APPROVED',
  MentorReviewDeclined = 'MENTOR_REVIEW_DECLINED',
  AiReview = 'AI_REVIEW',
  AiReviewFailed = 'AI_REVIEW_FAILED',
  AiReviewPassed = 'AI_REVIEW_PASSED'
}

export enum TestTaskFlow {
  GitHub = 'GITHUB',
  CodeEditor = 'CODE_EDITOR',
  Markup = 'MARKUP',
  Quiz = 'QUIZ',
  Attachment = 'ATTACHMENT',
  Web = 'WEB',
  Document = 'DOCUMENT'
}

export enum TestTaskType {
  LiveCoding = 'LIVE_CODING',
  HomeCoding = 'HOME_CODING',
  TopicTasks = 'TOPIC_TASKS',
  Exam = 'EXAM',
  Sandbox = 'SANDBOX',
  MorningTask = 'MORNING_TASK',
  DailyTask = 'DAILY_TASK'
}

export enum UserProfileOpener {
  Unspecified = 'Unspecified',
  TestTaskActivity = 'TestTaskActivity',
  ChatMention = 'ChatMention',
  ChatInfo = 'ChatInfo',
  ChatMessage = 'ChatMessage',
  ChatParticipantInfo = 'ChatParticipantInfo',
  ChatAddParticipant = 'ChatAddParticipant',
  PlatformSettings = 'PlatformSettings',
  ScheduleEventQuestion = 'ScheduleEventQuestion',
  ScheduleEventParticipant = 'ScheduleEventParticipant',
  ScheduleEventHost = 'ScheduleEventHost',
  EventSidebar = 'EventSidebar',
  UserProfilePreview = 'UserProfilePreview',
  MediaPreviewSlider = 'MediaPreviewSlider',
  GroupDashboard = 'GroupDashboard',
  HomeworkDashboard = 'HomeworkDashboard',
  LeaderboardTop = 'LeaderboardTop',
  LeaderboardUser = 'LeaderboardUser',
  GlobalSearch = 'GlobalSearch',
  ForumComment = 'ForumComment',
  DirectChatBanner = 'DirectChatBanner',
  AutomatedMessages = 'AutomatedMessagesTable',
  HomeworkReviews = 'HomeworkReviews'
}

export interface LEADCREATEDBASE {
  /**
   * @description
   * User email, for enhanced conversions tracking
   */
  email: string;

  /**
   * @description
   * User phone, for enhanced conversions tracking
   */
  phone: string;

  /**
   * @description
   *
   *     Conversion value is the amount of money that user brought to the company.
   *     In our case we calculate it like this:
   *     `conversion value = purchase probability * LTV * country coefficient`
   */
  conversionValue?: number;

  /**
   * @description
   * Currency code of the conversion value
   */
  currencyCode?: string;
}

export interface PLATFORMPROMO {
  /**
   * @description
   * Promo code slug
   */
  promoCodeSlug: string;
}

export interface BaseMessageCreated {
  chatId: number;
  senderId: number;

  /**
   * @description
   * Used to distinguish between Direct chats and others. More kinds can be added in the future.
   */
  chatKind: ChatKind;

  /**
   * @description
   * Denotes if message was sent from main chat page or practice page
   */
  chatLocation: ChatLocation;
  chatName?: string;
  isThread?: boolean;
  threadId?: number;
  groupName?: string;
  viewType?: string;
  hasMentorSupport?: boolean;

  /**
   * @description
   * Denotes if message was sent with concrete linked test task or with abstract 'other' one.
   */
  linkedTestTask?: LinkedTestTask;

  /**
   * @deprecated
   * Use senderId instead.
   */
  participantId?: number;
}

export interface PlatformExitPopUpContext {
  source: ExitPopUpSource;
  state: ExitPopUpState;
  type: ExitPopUpType;
}

export interface SIGNUPBASE {
  /**
   * @description
   * Auth user email, for gtm
   */
  email: string;

  /**
   * @description
   * Auth user phone, for gtm
   */
  phone: string;
  source: string;
  newslettersSubscribed?: boolean;
}

/**
 * @description
 * User survey
 */
export interface USERSURVEYBASE {
  course?: string;
  source: string;
  courseType?: CourseType;
  form_id?: string;
}

export interface VideoContext {
  courseSlug: string;
  topicSlug: string;
  videoTitle: string;
  videoId: number;
}

export interface CourseTopicProps {
  topicSlug: string;
  moduleSlug: string;
}

export interface MentorSolutionContext {
  taskSlug: string;
  solutionType: SolutionType;
  hasBeenPassed: boolean;
}

export interface ModuleRatingContext {
  rating?: number;
  courseSlug: string;
  courseType: CourseType;
  professionSlug?: string;
}

export interface TaskReviewProps {
  id: number;
  testTaskAttemptId: number;
  testTaskId: number;
  reviewerTestTaskAttemptId?: number;
  userId: number;
  source: string;
  status: TaskReviewStatus;
  reviewerId?: number;
  reviewType?: TaskReviewType;
}

export interface ReportProblemContext {
  courseSlug?: string;
  source?: string;
}

export interface SubscriptionPlan {
  pricingOptionSlug?: string;
  pricingOptionName?: string;
  subscriptionPlanName: string;
  subscriptionPrice: number;
  subscriptionDuration: string;
  subscriptionType: string;
  subscriptionPaymentType: string;
}

export interface SubscriptionProduct {
  subscriptionPlanName: string;
  subscriptionPrice: number;
  subscriptionDuration: string;
  subscriptionType: string;
  subscriptionPaymentType: string;
}

export interface TaskReview {
  id: number;
  testTaskAttemptId: number;
  testTaskId: number;
  reviewerTestTaskAttemptId?: number;
  userId: number;
  source: string;
  status: TaskReviewStatus;
  reviewerId?: number;
  reviewType?: TaskReviewType;
  reviewerRole?: ReviewerRole;
}

export interface WEBINARBASE {
  webinar: string;
}

/**
 * @description
 * FLEX lead created (Consultation form, URF (including generic consultation), Consultation survey
 */
export interface FxLeadCreated extends LEADCREATEDBASE {
  /**
   * @description
   * Course slug
   */
  course: string;

  /**
   * @description
   * Used promo code slug
   */
  promoCodeSlug: string;

  /**
   * @description
   * Source where lead came from to the consultation form
   */
  source: string;
}

/**
 * @description
 * FLEX TOP lead created (with leadScoreType = HOT or BLANK)
 */
export interface FxTopLeadCreated extends LEADCREATEDBASE {
  /**
   * @description
   * Course slug
   */
  course: string;

  /**
   * @description
   * Used promo code slug
   */
  promoCodeSlug: string;

  /**
   * @description
   * Source where lead came from to the consultation form
   */
  source: string;
}

/**
 * @description
 * FULLTIME lead created (FULLTIME landing, URF, FULLTIME survey)
 */
export interface FtLeadCreated extends LEADCREATEDBASE {
  /**
   * @description
   * Course slug
   */
  course: string;

  /**
   * @description
   * Source where lead came from to the application form
   */
  source: string;
}

/**
 * @description
 * WEBINAR lead created
 */
export interface WebinarLeadCreated extends LEADCREATEDBASE {
  /**
   * @description
   * Webinar slug
   */
  webinar: string;
}

/**
 * @description
 * MARATHON lead created
 */
export interface MarathonLeadCreated extends LEADCREATEDBASE {
  /**
   * @description
   * Marathon slug
   */
  marathon: string;
}

/**
 * @description
 * User saw platform promo
 */
export interface PlatformPromoIsShown extends PLATFORMPROMO {
}

/**
 * @description
 * User accepted platform promo
 */
export interface PlatformPromoIsAccepted extends PLATFORMPROMO {
}

/**
 * @description
 * User submitted the purchase consultation form (no purchase, only consultation that he is ready to buy a course)
 */
export interface PurchaseFormSubmitted {
  course: string;
  source: string;
}

/**
 * @description
 * User clicked Buy button
 */
export interface BuyButtonClicked {
  subscriptionPlan: string;
}

/**
 * @description
 * User submitted the billing form
 */
export interface BillingAddressFormSubmitted {
}

/**
 * @description
 * User clicked on the support icon
 */
export interface SupportIconClicked {
  providerName: SupportSocialNames;
}

/**
 * @description
 * User opened the AI assistant widget
 */
export interface AiAssistantWidgetOpened {
  userId: number;
  chatId: number;
  testTaskId: number;
  testTaskAttemptId: number;
  flow: string;
  taskSlug: string;
  context: AiAssistantContext;
}

/**
 * @description
 * User clicked quick action button
 */
export interface AiAssistantQuickActionButtonClicked {
  chatId: number;
  userId: number;
  senderId: number;
  testTaskId: number;
  testTaskAttemptId: number;
  flow: string;
  taskSlug: string;
  action: string;
}

/**
 * @description
 * AI assistant chat message sent by the user
 */
export interface AiAssistantChatMessageSent {
  chatId: number;
  userId: number;
  senderId: number;
  role: ParticipantRole;
  cost?: number;
}

/**
 * @description
 * User accessed AI chat
 */
export interface AiAssistantAiChatTriggered {
  testTaskId: number;
  testTaskAttemptId: number;
  flow: string;
  taskSlug: string;
  trigger: AiChatTrigger;
}

/**
 * @description
 * A student completed AI Review onboarding
 */
export interface AiReviewOnboardingCompleted {
}

/**
 * @description
 * Triggered when existing calendar event is canceled.
 */
export interface CalendarEventCanceled {
  eventId: number;
}

/**
 * @description
 * User earned a certificate
 */
export interface CertificateEarned {
  certificateType: CertificateType;
}

/**
 * @description
 * User downloaded a certificate PDF
 */
export interface CertificateDownloaded {
  certificateType: CertificateType;
  page: string;
}

/**
 * @description
 * User shared a certificate
 */
export interface CertificateShared {
  certificateType: CertificateType;
  page: string;
}

/**
 * @description
 * User open the certificates page
 */
export interface CertificatePageOpened {
  courseSlug: string;
}

/**
 * @description
 * Triggered from API when a direct chat is created.
 */
export interface ChatDirectChatCreated {
}

/**
 * @description
 * Triggered from API when a user goes to a direct chat.
 */
export interface ChatGoToDirectChat {
  /**
   * @description
   * It can be profile or profile preview.
   */
  source: GoToDirectChatSource;

  /**
   * @description
   * Pathname from router to understand more precisely from where the user wnt to the direct chat.
   */
  pageURL: string;
}

/**
 * @description
 * Triggered from FE when user opens a chat on practice page.
 */
export interface ChatOpenPracticePageChat {
  /**
   * @description
   * From which tab the chat was opened.
   */
  source: PracticePageChatSource;
}

/**
 * @description
 * Triggered from FE when user sends a new message.
 */
export interface ChatMessageCreated extends BaseMessageCreated {
}

/**
 * @description
 * Triggered from FE when user sends a new open question.
 */
export interface ChatOpenQuestionCreated extends BaseMessageCreated {
}

/**
 * @description
 * Triggered from FE when user sends a new poll.
 */
export interface ChatPollCreated extends BaseMessageCreated {
}

/**
 * @description
 * Triggered from FE when user clicks LoadMore button to load more chats of some group.
 */
export interface ChatLoadMoreChats {
  chatGroup: ChatGroup;
}

/**
 * @description
 * Triggered from api when student tag was used
 */
export interface ChatStudentTagUsed {
  chatId: number;
}

/**
 * @description
 * Triggered from client when user opens a chat
 */
export interface ChatViewOpened {
  chatId: number;
}

/**
 * @description
 * A student opened the check-in form
 */
export interface StudentCheckInFormOpened {
  userId: number;
}

/**
 * @description
 * A student sent the check-in form
 */
export interface StudentCheckInFormSent {
  userId: number;
  progressRating: number;
  motivationRating: number;
}

/**
 * @description
 * Triggered when a new attempt is created (usually when a user starts a task).
 *   Was added to track the number of attempts created from the `startTask(CreateTestTaskAttempt) mutation` and detect duplicates.
 */
export interface CodeEditorAttemptCreated {
  testTaskId: number;
  testTaskLinkId: number;
  testTaskVersionId: number;

  /**
   * @description
   * From which place was the attempt created? E.g. `startTask(CreateTestTaskAttempt) mutation`
   */
  source: string;
}

/**
 * @description
 * Triggered from FE when user update english level.
 */
export interface EnglishLevelUpdated {
  userId: number;
  oldEnglishLevel?: UserEnglishLevel;
  newEnglishLevel?: UserEnglishLevel;
  courseType?: CourseType;
  studentsGroup?: string;
}

/**
 * @description
 * Triggered from FE when user click renew subscription button on English page.
 */
export interface EnglishRenewSubscriptionBtnClicked {
}

/**
 * @description
 * Triggered from FE when user close or clock CTA button on personal english lessons banner.
 */
export interface EnglishPersonalLessonsBannerInteracted {
  action: PersonalLessonsBannerAction;
}

/**
 * @description
 * Triggered when user attempts to leave the page with unsaved changes and the exit pop up is shown
 */
export interface PlatformExitPopUpAction extends PlatformExitPopUpContext {
}

/**
 * @description
 * Triggered when user goes to new application after failing previous
 */
export interface FulltimeFlowReApplyButtonClicked {
  course: string;
  source: ReApplyButtonSource;
}

/**
 * @description
 * User opened the PR
 */
export interface GithubTaskPrOpened {
  testTaskSlug: string;
}

/**
 * @description
 * User received a review on the PR
 */
export interface GithubTaskPrReviewed {
  testTaskSlug: string;
  reviewerRole: ReviewerRole;
  reviewState: GitHubReviewState;
}

/**
 * @description
 * User closed the PR
 */
export interface GithubTaskPrClosed {
  testTaskSlug: string;
}

/**
 * @description
 * User opened the group dashboard page
 */
export interface GroupDashboardPageOpened {
  tab: GroupDashboardTab;
  courseSlug: string;
  groupName: string;
  isPageAdmin: boolean;
}

/**
 * @description
 * Admin opened the task sidebar
 */
export interface GroupDashboardTaskSidebarOpened {
  courseSlug: string;
  taskSlug: string;
}

/**
 * @description
 * Admin open chat from dashboard
 */
export interface GroupDashboardChatOpenFromDashboard {
  groupName: string;
  messagesCount: number;
}

/**
 * @description
 * Admin opened homework task for review
 */
export interface GroupDashboardHomeworkTaskOpenedForReview {
  courseSlug: string;
}

/**
 * @description
 * User clicked to view a course author's LinkedIn profile
 */
export interface LandingCourseAuthorLinkClicked {
  authorName?: string;
  courseSlug?: string;
}

/**
 * @description
 * User clicked on the CTA button on the reviews page
 */
export interface LandingReviewsCtaButtonClicked {
  type?: string;
}

/**
 * @description
 * User clicked on the button in a review card
 */
export interface LandingReviewButtonClicked {
  /**
   * @description
   * The source of the event:
   *     - ReviewsPage: Clicked on the button in a review card on the reviews page
   */
  source: ReviewButtonClickedSource;

  /**
   * @description
   * The type of button:
   *     - read_original: User clicked to read the original review
   *     - linkedin: User clicked to view the reviewer's LinkedIn profile
   */
  type: ReviewButtonType;

  /**
   * @description
   * The label of the course the review is for
   */
  courseLabel?: string;
}

/**
 * @description
 * Visits the sign up page
 */
export interface LandingSignUpFormView {
}

/**
 * @description
 * When new user submits the sign up form
 */
export interface LandingSignUpFormSubmitted extends SIGNUPBASE {
  /**
   * @description
   * Course slug
   */
  course: string;

  /**
   * @description
   * Course type (e.g. 'PREPAID', 'POSTPAID')
   */
  courseType: CourseType;
  socialMedia?: string;
  device?: DeviceTypes;
}

/**
 * @description
 * Triggered on creating new temporary user
 */
export interface LandingTemporaryUserCreated extends SIGNUPBASE {
  device?: DeviceTypes;
}

/**
 * @description
 * Recruiter submits the sign up form
 */
export interface LandingHireSignUpFormSubmitted extends SIGNUPBASE {
}

/**
 * @description
 * User started the user survey
 */
export interface LandingUserSurveyStarted extends USERSURVEYBASE {
}

/**
 * @description
 * User completed the user survey
 */
export interface LandingUserSurveyCompleted extends USERSURVEYBASE {
}

/**
 * @description
 * User changed the question in the user survey
 */
export interface LandingUserSurveyQuestionChanged {
  form_id: string;
  question_ref: string;
}

/**
 * @description
 * User clicked button on the success page after the user survey
 */
export interface LandingUserSurveySuccessButtonClicked {
  course: string;
  type?: string;
}

/**
 * @description
 * User clicked on the monetary bonus section
 */
export interface LandingMonetaryBonusClicked {
  currentStep: string;
}

/**
 * @description
 * User clicked on the agreement button
 */
export interface LandingAgreementButtonClicked {
  courseType?: CourseType;
}

/**
 * @description
 * User submitted email to receive the agreement
 */
export interface LandingAgreementEmailAddressSubmitted {
  email: string;
}

/**
 * @description
 * User clicked on the PrivatBank cashback section
 */
export interface LandingPrivatBankCashback {
  action: CashbackAction;
  source: string;
}

/**
 * @description
 * User submitted the consultation form
 */
export interface LandingConsultationFormSubmitted {
  /**
   * @description
   * Course slug
   */
  course: string;

  /**
   * @description
   * Source of the event
   */
  source: string;

  /**
   * @description
   * User phone number (used for GoogleAnalytics)
   */
  userPhone?: string;

  /**
   * @description
   * Promo code slug, if applied
   */
  promoCodeSlug?: string;

  /**
   * @description
   * Lead score, either blank or top. If neither, the prop is omitted
   */
  leadScore?: LeadScore;
}

/**
 * @description
 * User opened the hire graduate form
 */
export interface LandingHireGraduateFormOpened {
  source: HireGraduateFormSource;
}

/**
 * @description
 * User clicked on a button to select a course
 */
export interface LandingButtonClickCourseSelection {
  /**
   * @description
   * Course slug
   */
  course: string;

  /**
   * @description
   * Course type (e.g. 'PREPAID', 'POSTPAID')
   */
  courseType: CourseType;
}

/**
 * @description
 * User clicked on a link to view a course
 */
export interface LandingCourseLinkClicked {
  /**
   * @description
   * Course slug
   */
  course: string;

  /**
   * @description
   * Course type (e.g. 'PREPAID', 'POSTPAID')
   */
  courseType: CourseType;
}

/**
 * @description
 * User clicked on a button in the promo section
 */
export interface LandingPromoButtonClicked {
  /**
   * @description
   * The promo name
   */
  source: string;
}

/**
 * @description
 * User clicked on a button in the promo banner
 */
export interface LandingPromoBannerButtonClicked {
  /**
   * @description
   * The promo name
   */
  source: string;
}

/**
 * @description
 * User clicked on a button
 */
export interface LandingButtonClicked {
  /**
   * @description
   * The section/component name
   */
  source: string;

  /**
   * @description
   * Course type (e.g. 'PREPAID', 'POSTPAID')
   */
  courseType?: CourseType;
}

/**
 * @description
 * User clicked on the CTA button in the platform demo section
 */
export interface LandingPlatformDemoCtaButtonClicked {
  /**
   * @description
   * The demo section name
   */
  section: string;

  /**
   * @description
   * The course slug of the visited page
   */
  courseSlug?: string;
}

/**
 * @description
 * User reset password
 */
export interface LandingResetPassword {
  action: ResetPasswordAction;
}

/**
 * @description
 * User changed domain
 */
export interface LandingUserDomainChanged {
  oldDomain: string;
  newDomain: string;
  source: string;
}

/**
 * @description
 * Telegram popup interaction
 */
export interface LandingTelegramPopup {
  action: PopupAction;
}

/**
 * @description
 * Non-relevant country popup interaction
 */
export interface LandingNonRelevantCountryPopup {
  action: PopupAction;
  selectedCountry?: string;
}

/**
 * @description
 * Open trial access process
 */
export interface LandingOpenTrialAccess {
  status: OpenTrialAccessStatus;
  message?: string;
}

/**
 * @description
 * User applied a promo code
 */
export interface LandingPromoCodeApplied {
  slug: string;
  status: PromoCodeStatus;
  type: PromoCodeType;

  /**
   * @description
   * Section or cta component name where user came from
   */
  source: string;
}

/**
 * @description
 * Exit popup opened
 */
export interface LandingExitPopupOpened {
  source: string;
}

/**
 * @description
 * Exit popup closed
 */
export interface LandingExitPopupClosed {
  source: string;
}

/**
 * @description
 * Triggered when the user opens the webchat
 */
export interface LandingWebChatOpened {
}

/**
 * @description
 * Triggered when the user sends a message in the webchat
 */
export interface LandingWebChatUserMessage {
}

/**
 * @description
 * Triggered from API when user got XP for answer in thread.
 */
export interface LeaderboardUserXpEarnedForReplyInThread {
  xp: number;
}

/**
 * @description
 *
 *     Video reached some percent.
 *     Can be used as video playing started (replayingPointInPercents = 0),
 *     finished (replayingPointInPercents = 100) or just some point in the middle.
 *     Will be limited to several times per video.
 *     Probably 0, 25, 50, 75, 100 percents.
 *   
 */
export interface LearnVideoPlayedToPoint extends VideoContext {
  videoType: VideoType;
  originalVideoType: VideoType;
  replayingPointInPercents: number;

  /**
   * @description
   *
   *     Added to be able to analyze if students finish short videos more often than long ones.
   *     Or something like that.
   *   
   */
  videoDurationInSeconds: number;
}

/**
 * @description
 * Triggered when user ends Linear flow onboarding
 */
export interface LinearLearningFlowOnboardingWasShown extends CourseTopicProps {
  completed: boolean;
}

/**
 * @description
 * Triggered when user opens learn item in minimized view
 */
export interface LinearLearningFlowOpenedInMinimizedView extends CourseTopicProps {
  item: MinimizedViewItem;
}

/**
 * @description
 *
 *     Module was created from another one.
 *     Can be used to track how many times feature was used
 *     and how many manual work was omitted.
 *   
 */
export interface LmsEditorModuleCreatedFromExisting {
  professionSlug: string;
  newModuleSlug: string;
  addedDailyTasksCount: number;
  createdCourseTopicsCount: number;
  addedCourseTopicTasksCount: number;
  copiedCourseTopicTheoryFilesCount: number;
}

/**
 * @description
 *
 *     Profession was created from another one.
 *     Can be used to track how many times feature was used
 *     and how many manual work was omitted.
 *   
 */
export interface LmsEditorProfessionCreatedFromExisting {
  templateProfessionSlug: string;
  newProfessionSlug: string;
  addedMorningTasksCount: number;
  addedLiveCodingTasksCount: number;
  addedAssignmentTasksCount: number;
}

/**
 * @description
 * A mentor opened their contractor timeline
 */
export interface MatchWorkingTimeContractorTimelineOpenedByMentor {
}

/**
 * @description
 * A mentor commented their idle time
 */
export interface MatchWorkingTimeIdleTimeCommented {
}

/**
 * @description
 * Triggered when mentor solution is opened
 */
export interface MentorSolutionMentorSolutionOpened extends MentorSolutionContext {
}

/**
 * @description
 * Triggered on the interaction with the new mobile app version onboarding
 */
export interface MobileAppNewVersionOnboardingOpened {
  action: AppVersionOnboardingAction;

  /**
   * @description
   * Current app version
   */
  appVersion: string;
}

/**
 * @description
 * Triggered when completion module screen is opened
 */
export interface ModuleRatingOpen extends ModuleRatingContext {
}

/**
 * @description
 * Triggered when user rates the module
 */
export interface ModuleRatingRate extends ModuleRatingContext {
}

/**
 * @description
 * Triggered when a student clicks on the Proceed to Logic Test button
 */
export interface ModuleRatingProceedToLogicTestButtonClicked extends ModuleRatingContext {
}

/**
 * @description
 * Trigger when the user sees a discount banner and click on Consultation button
 */
export interface ModuleRatingConsultationButtonClicked extends ModuleRatingContext {
}

/**
 * @description
 * Triggered when student clicked to update payment method button
 */
export interface PaymentUpdateMethodButtonClicked {
  subscriptionPlanName?: string;
  subscriptionType?: string;
}

/**
 * @description
 * Triggered when student updated payment method
 */
export interface PaymentMethodUpdated {
}

/**
 * @description
 * Triggered on initial payment method setup without payment
 */
export interface PaymentMethodCreated {
  provider: string;
  status: string;
}

/**
 * @description
 * Triggered when refund is successfuly processed on a provider side
 */
export interface PaymentRefundProcessed {
  provider: string;
  subscriptionPrice: number;
  subscriptionType: string;
  subscriptionPaymentType: string;
}

/**
 * @description
 * Triggered when payment is disputed by customer on a provider side
 */
export interface PaymentDisputed {
  provider: string;
  subscriptionPlan: string;
  reason: string;
}

/**
 * @description
 * Triggered when Principia exclusive sale is created
 */
export interface PaymentExclusiveSaleCreated {
}

/**
 * @description
 * Triggered when a student starts reviewing his peers task
 */
export interface PeerReviewStart extends TaskReviewProps {
}

/**
 * @description
 * Triggered when a students task was reviewed by his peer
 */
export interface PeerReviewReviewTask extends TaskReviewProps {
}

/**
 * @description
 * Triggered when students work was not peer reviewed within a day
 */
export interface PeerReviewMovePendingPeerToPending extends TaskReviewProps {
}

/**
 * @description
 * Triggered when students work was taken on a peer review, but was not finished in time
 */
export interface PeerReviewExpireOnPeerReview extends TaskReviewProps {
}

/**
 * @description
 * Triggered when students re-submits his work after a reject from mentor or peer
 */
export interface PeerReviewTaskReadyForReview extends TaskReviewProps {
}

/**
 * @description
 * Triggered when lead clicked become_student button on platform
 */
export interface PlatformConsultationBecomeStudentButtonClicked {
}

/**
 * @description
 * Triggered when lead clicked get_consultation button on platform
 */
export interface PlatformConsultationGetConsultationButtonClicked {
}

/**
 * @description
 * Triggered when lead redirected to FT application from platform
 */
export interface PlatformConsultationLeadRedirectedToFtApplication {
}

/**
 * @description
 * User changed the theme preference
 */
export interface PlatformThemePreferenceChanged {
  chosenTheme: string;
}

/**
 * @description
 * User completed platform theme onboarding
 */
export interface PlatformThemePreferenceOnboardingCompleted {
  chosenTheme: string;
}

/**
 * @description
 * User submitted employment profile
 */
export interface ProfileEmploymentProfileSubmitted {
}

/**
 * @description
 * Triggered when user reports a problem
 */
export interface ReportProblemProblemReport extends ReportProblemContext {
}

/**
 * @description
 * Triggered when user redirects to Mate canny page
 */
export interface ReportProblemRedirectToCanny extends ReportProblemContext {
}

/**
 * @description
 * User opened modal with streaks
 */
export interface StreaksWidgetOpened {
}

/**
 * @description
 * Triggered when a user completes the subscription purchase process by finalizing payment
 */
export interface SubscriptionPurchaseCompleted extends SubscriptionPlan {
  isFirstSubscription: boolean;
  paymentMethod: string;
}

/**
 * @description
 * Triggered when a recurring (second and onwards) subscription payment was processed
 */
export interface SubscriptionRecurringPurchaseProcessed extends SubscriptionPlan {
  isFirstSubscription: boolean;
  paymentMethod: string;
  retryAttempt: number;
  status: SubscriptionRecurringPurchaseStatus;
}

/**
 * @description
 * Triggered when user starts cancelling their subscription
 */
export interface SubscriptionCancellationStarted extends SubscriptionPlan {
  acceptedPaymentsCount: number;
}

/**
 * @description
 * Triggered when user successfully completes the cancellation process of their subscription.
 */
export interface SubscriptionCancellationFinished extends SubscriptionPlan {
  acceptedPaymentsCount: number;
}

/**
 * @description
 * Triggered when user accepts cancellation promo and pauses their subscription / gets 1 month for free
 */
export interface SubscriptionCancellationPromoUsed extends SubscriptionPlan {
  acceptedPaymentsCount: number;
}

/**
 * @description
 * Triggered when user is shown a block with information about the next expected payment on checkout page
 */
export interface SubscriptionNextPaymentBlockShown {
  action: NextPaymentBlockAction;
}

/**
 * @description
 * Triggered when user has clicked on CTA on the next payment block on checkout page
 */
export interface SubscriptionNextPaymentCtaClicked {
  action: NextPaymentBlockAction;
}

/**
 * @description
 * Triggered when a student saw a modal with the paywall
 */
export interface SubscriptionProductPaywallModalOpened {
  paywallLocation: PaywallLocations;
}

/**
 * @description
 * Triggered when a user clicks on the 'Pay' button on the mocked checkout modal
 */
export interface SubscriptionProductCheckoutPayClicked {
  paymentMethod: PaymentMethods;
  validationError?: string;
}

/**
 * @description
 * Triggered when a user sees the sign up form on the landing page for a codecademy clone test
 */
export interface SubscriptionProductLandingSignupFormView {
}

/**
 * @description
 * Triggered when a user submits an email on in the checkout
 */
export interface SubscriptionProductCheckoutEmailSubmitted {
}

/**
 * @description
 * Triggered when a student closed a modal with the paywall
 */
export interface SubscriptionProductPaywallModalClosed {
  paywallLocation: PaywallLocations;
}

/**
 * @description
 * Triggered when a user selects the plan in the paywall by clicking the 'Upgrade' button or user clicks on the plan on the landing page
 */
export interface SubscriptionProductSubscriptionPlanSelected extends SubscriptionProduct {
  pricingOptionName?: string;
  pricingOptionSlug: string;
  source: SubscriptionProductSources;
  paywallLocation: PaywallLocations;
  device?: DeviceTypes;
}

/**
 * @description
 * Triggered when a user completes the subscription purchase process on UI
 */
export interface SubscriptionProductPurchaseCompleted extends SubscriptionProduct {
  paymentMethod: PaymentMethods;
  pricingOptionName?: string;
  pricingOptionSlug?: string;
}

/**
 * @description
 * Triggered when a user is redirected to the checkout page
 */
export interface SubscriptionProductTemporaryCredUserRedirectedToCheckout extends SubscriptionProduct {
}

/**
 * @description
 * Triggered when temporary user email set to actual
 */
export interface SubscriptionProductTemporaryUserEmailUpdated {
  temporaryEmail: string;
  actualEmail: string;
}

/**
 * @description
 * Triggered when a user visits the checkout page
 */
export interface SubscriptionProductCheckoutVisit {
  registrationType: RegistrationType;
  provider: CheckoutPaymentProvider;
}

/**
 * @description
 * Triggered when an error occurs on the checkout page
 */
export interface SubscriptionProductCheckoutError {
  errorMessage: string;
  errorCode: string;
  emailError?: string;
}

/**
 * @description
 * Triggered when a user closes the checkout page
 */
export interface SubscriptionProductCheckoutClosed {
}

/**
 * @description
 * Triggered when a user completes sign up form
 */
export interface SubscriptionProductUserCreated {
  redirectUrl?: string;
}

/**
 * @description
 * Triggered when a user visits the platform pricing page (intro-offer )
 */
export interface SubscriptionProductPlatformPricingVisit {
}

/**
 * @description
 * Triggered when a user closes the pricing page (intro-offer)
 */
export interface SubscriptionProductPricingClosed {
}

/**
 * @description
 * Triggered when a user sees the modal with the option to receive the link to the desktop version of the platform via email
 */
export interface SubscriptionProductDesktopEmailScreenShown {
}

/**
 * @description
 * Triggered when a user clicks on a button in the modal with the option to receive the link to the desktop version of the platform via email
 */
export interface SubscriptionProductDesktopEmailButtonClicked {
  button: DesktopLinkEmailButtons;
}

/**
 * @description
 * Triggered when a user clicks on the 'Renew Subscription' button on My Subscription page
 */
export interface SubscriptionProductRenewSubscriptionButtonClicked {
  location: RenewSubscriptionButtonLocations;
}

/**
 * @description
 * Triggered when a user completes a quiz question
 */
export interface SubscriptionProductQuizQuestionCompleted {
  form_id?: string;
  question_ref?: string;
}

/**
 * @description
 * exit offer popup is shown on checkout close
 */
export interface SubscriptionProductExitOfferShown {
}

/**
 * @description
 * exit offer popup is closed by user
 */
export interface SubscriptionProductExitOfferClosed {
}

/**
 * @description
 * Triggered when task was reviewed by mentor
 */
export interface TaskReviewTaskWasReviewed extends TaskReview {
}

/**
 * @description
 * Triggered when review card shown to mentor
 */
export interface TaskReviewStartTaskReview extends TaskReview {
}

/**
 * @description
 * Triggered when student send link without access in attachment task to review
 */
export interface TaskReviewDeclinedByLinkAccess {
  linkType: AttachmentTaskLinkTypes;
}

/**
 * @description
 * Triggered when a student joined a team project waiting list
 */
export interface TeamProjectJoinedTeamProject {
  professionSlug: string;
}

/**
 * @description
 * Triggered when a student left a team project waiting list
 */
export interface TeamProjectLeftTeamProject {
  professionSlug: string;
}

/**
 * @description
 * Triggered when a student started a team project
 */
export interface TeamProjectStartedTeamProject {
  teamProjectId: number;
  professionSlug: string;
}

/**
 * @description
 * Triggered when a student opened the team project intro page
 */
export interface TeamProjectIntroPageOpened {
  step: TeamProjectIntroSteps;
}

/**
 * @description
 * Triggered when a student clicked on the join button on the team project intro page
 */
export interface TeamProjectIntroPageJoinBtnClicked {
}

/**
 * @description
 * Triggered when a student clicked on the leave button on the team project intro page
 */
export interface TeamProjectIntroPageLeaveBtnClicked {
}

/**
 * @description
 * Triggered when a student clicked on the general chat button on the team project intro page
 */
export interface TeamProjectIntroPageGeneralChatBtnClicked {
}

/**
 * @description
 * Triggered when a student clicked on the goto first topic button on the team project intro page
 */
export interface TeamProjectIntroPageGotoFirstTopicBtnClicked {
}

/**
 * @description
 * Triggered when a student clicked on the team chat button on the team project intro page
 */
export interface TeamProjectIntroPageTeamChatBtnClicked {
}

/**
 * @description
 * Triggered when a student opens a test task for the first time
 */
export interface TestTaskFirstOpenedByStudent {
  testTaskId: number;
  testTaskAttemptId: number;
  testTaskVersionId: number;
  programmingLanguage: ProgrammingLanguage;
  flow: TestTaskFlow;
  slug: string;
  testTaskType: TestTaskType;
}

/**
 * @description
 * Triggered when a task is approved by mentor for the first time
 */
export interface TestTaskFirstSuccessfulCheckByMentor {
  testTaskId: number;
  testTaskAttemptId: number;
  testTaskVersionId: number;
  programmingLanguage: ProgrammingLanguage;
  flow: TestTaskFlow;
  slug: string;
  testTaskType: TestTaskType;
}

/**
 * @description
 * Test task activity banner clicked
 */
export interface TestTaskActivityBannerClicked {
  testTaskSlug?: string;
  courseSlug?: string;
  courseTopicSlug?: string;
  programmingLanguage?: string;
  flow?: string;
  status?: string;
}

/**
 * @description
 * Test task activity sidebar user profile clicked within section
 */
export interface TestTaskActivitySidebarUserProfileMiniatureClicked {
  section: string;
  position: number;
}

/**
 * @description
 * Test task activity sidebar user profile direct message clicked within section
 */
export interface TestTaskActivitySidebarUserProfileDirectMessageClicked {
  section: string;
  position: number;
}

/**
 * @description
 * Test task activity sidebar Solving now section expanded
 */
export interface TestTaskActivitySidebarSolvingNowSectionExpanded {
}

/**
 * @description
 * User task solution onboarding status changed
 */
export interface TestTaskActivityUserTaskSolutionOnboarding {
  status: string;
}

/**
 * @description
 * User task solution opened
 */
export interface TestTaskActivityUserTaskSolutionOpened {
}

/**
 * @description
 * User accepted new timezone that we detected
 */
export interface TimezoneNewTimezoneAccepted {
  newTimeZone: string;
  oldTimeZone?: string;
}

/**
 * @description
 * User closed modal with new timezone that we detected
 */
export interface TimezoneNewTimezoneModalClosed {
  newTimeZone: string;
  oldTimeZone?: string;
}

/**
 * @description
 * User profile was opened
 */
export interface UserProfileOpened {
  pageUrl: string;
  opener: UserProfileOpener;
}

/**
 * @description
 * Profile status was updated
 */
export interface UserProfileStatusUpdated {
}

/**
 * @description
 * Profile edit button clicked
 */
export interface UserProfileEditClicked {
}

/**
 * @description
 * Profile privacy setting updated
 */
export interface UserProfilePrivacySettingUpdated {
  value: string;
}

/**
 * @description
 * Profile updated
 */
export interface UserProfileUpdated {
}

/**
 * @description
 * User has submitted the webinar sign up form
 */
export interface WebinarSignUpFormSubmitted extends WEBINARBASE {
}

/**
 * @description
 * User has clicked the 'Register with another account' button
 */
export interface WebinarRegistrationWithAnotherAccountClicked extends WEBINARBASE {
}

export type EventFields<E> = (keyof E)[];

export const fxLeadCreatedFields: EventFields<FxLeadCreated> = ['course', 'promoCodeSlug', 'source', 'email', 'phone', 'conversionValue', 'currencyCode'];

export const fxTopLeadCreatedFields: EventFields<FxTopLeadCreated> = ['course', 'promoCodeSlug', 'source', 'email', 'phone', 'conversionValue', 'currencyCode'];

export const ftLeadCreatedFields: EventFields<FtLeadCreated> = ['course', 'source', 'email', 'phone', 'conversionValue', 'currencyCode'];

export const webinarLeadCreatedFields: EventFields<WebinarLeadCreated> = ['webinar', 'email', 'phone', 'conversionValue', 'currencyCode'];

export const marathonLeadCreatedFields: EventFields<MarathonLeadCreated> = ['marathon', 'email', 'phone', 'conversionValue', 'currencyCode'];

export const platformPromoIsShownFields: EventFields<PlatformPromoIsShown> = ['promoCodeSlug'];

export const platformPromoIsAcceptedFields: EventFields<PlatformPromoIsAccepted> = ['promoCodeSlug'];

export const purchaseFormSubmittedFields: EventFields<PurchaseFormSubmitted> = ['course', 'source'];

export const buyButtonClickedFields: EventFields<BuyButtonClicked> = ['subscriptionPlan'];

export const billingAddressFormSubmittedFields: EventFields<BillingAddressFormSubmitted> = [];

export const supportIconClickedFields: EventFields<SupportIconClicked> = ['providerName'];

export const aiAssistantWidgetOpenedFields: EventFields<AiAssistantWidgetOpened> = ['userId', 'chatId', 'testTaskId', 'testTaskAttemptId', 'flow', 'taskSlug', 'context'];

export const aiAssistantQuickActionButtonClickedFields: EventFields<AiAssistantQuickActionButtonClicked> = ['chatId', 'userId', 'senderId', 'testTaskId', 'testTaskAttemptId', 'flow', 'taskSlug', 'action'];

export const aiAssistantChatMessageSentFields: EventFields<AiAssistantChatMessageSent> = ['chatId', 'userId', 'senderId', 'role', 'cost'];

export const aiAssistantAiChatTriggeredFields: EventFields<AiAssistantAiChatTriggered> = ['testTaskId', 'testTaskAttemptId', 'flow', 'taskSlug', 'trigger'];

export const aiReviewOnboardingCompletedFields: EventFields<AiReviewOnboardingCompleted> = [];

export const calendarEventCanceledFields: EventFields<CalendarEventCanceled> = ['eventId'];

export const certificateEarnedFields: EventFields<CertificateEarned> = ['certificateType'];

export const certificateDownloadedFields: EventFields<CertificateDownloaded> = ['certificateType', 'page'];

export const certificateSharedFields: EventFields<CertificateShared> = ['certificateType', 'page'];

export const certificatePageOpenedFields: EventFields<CertificatePageOpened> = ['courseSlug'];

export const chatDirectChatCreatedFields: EventFields<ChatDirectChatCreated> = [];

export const chatGoToDirectChatFields: EventFields<ChatGoToDirectChat> = ['source', 'pageURL'];

export const chatOpenPracticePageChatFields: EventFields<ChatOpenPracticePageChat> = ['source'];

export const chatMessageCreatedFields: EventFields<ChatMessageCreated> = ['chatId', 'senderId', 'chatKind', 'chatLocation', 'chatName', 'isThread', 'threadId', 'groupName', 'viewType', 'hasMentorSupport', 'linkedTestTask', 'participantId'];

export const chatOpenQuestionCreatedFields: EventFields<ChatOpenQuestionCreated> = ['chatId', 'senderId', 'chatKind', 'chatLocation', 'chatName', 'isThread', 'threadId', 'groupName', 'viewType', 'hasMentorSupport', 'linkedTestTask', 'participantId'];

export const chatPollCreatedFields: EventFields<ChatPollCreated> = ['chatId', 'senderId', 'chatKind', 'chatLocation', 'chatName', 'isThread', 'threadId', 'groupName', 'viewType', 'hasMentorSupport', 'linkedTestTask', 'participantId'];

export const chatLoadMoreChatsFields: EventFields<ChatLoadMoreChats> = ['chatGroup'];

export const chatStudentTagUsedFields: EventFields<ChatStudentTagUsed> = ['chatId'];

export const chatViewOpenedFields: EventFields<ChatViewOpened> = ['chatId'];

export const studentCheckInFormOpenedFields: EventFields<StudentCheckInFormOpened> = ['userId'];

export const studentCheckInFormSentFields: EventFields<StudentCheckInFormSent> = ['userId', 'progressRating', 'motivationRating'];

export const codeEditorAttemptCreatedFields: EventFields<CodeEditorAttemptCreated> = ['testTaskId', 'testTaskLinkId', 'testTaskVersionId', 'source'];

export const englishLevelUpdatedFields: EventFields<EnglishLevelUpdated> = ['userId', 'oldEnglishLevel', 'newEnglishLevel', 'courseType', 'studentsGroup'];

export const englishRenewSubscriptionBtnClickedFields: EventFields<EnglishRenewSubscriptionBtnClicked> = [];

export const englishPersonalLessonsBannerInteractedFields: EventFields<EnglishPersonalLessonsBannerInteracted> = ['action'];

export const platformExitPopUpActionFields: EventFields<PlatformExitPopUpAction> = ['source', 'state', 'type'];

export const fulltimeFlowReApplyButtonClickedFields: EventFields<FulltimeFlowReApplyButtonClicked> = ['course', 'source'];

export const githubTaskPrOpenedFields: EventFields<GithubTaskPrOpened> = ['testTaskSlug'];

export const githubTaskPrReviewedFields: EventFields<GithubTaskPrReviewed> = ['testTaskSlug', 'reviewerRole', 'reviewState'];

export const githubTaskPrClosedFields: EventFields<GithubTaskPrClosed> = ['testTaskSlug'];

export const groupDashboardPageOpenedFields: EventFields<GroupDashboardPageOpened> = ['tab', 'courseSlug', 'groupName', 'isPageAdmin'];

export const groupDashboardTaskSidebarOpenedFields: EventFields<GroupDashboardTaskSidebarOpened> = ['courseSlug', 'taskSlug'];

export const groupDashboardChatOpenFromDashboardFields: EventFields<GroupDashboardChatOpenFromDashboard> = ['groupName', 'messagesCount'];

export const groupDashboardHomeworkTaskOpenedForReviewFields: EventFields<GroupDashboardHomeworkTaskOpenedForReview> = ['courseSlug'];

export const landingCourseAuthorLinkClickedFields: EventFields<LandingCourseAuthorLinkClicked> = ['authorName', 'courseSlug'];

export const landingReviewsCtaButtonClickedFields: EventFields<LandingReviewsCtaButtonClicked> = ['type'];

export const landingReviewButtonClickedFields: EventFields<LandingReviewButtonClicked> = ['source', 'type', 'courseLabel'];

export const landingSignUpFormViewFields: EventFields<LandingSignUpFormView> = [];

export const landingSignUpFormSubmittedFields: EventFields<LandingSignUpFormSubmitted> = ['course', 'courseType', 'socialMedia', 'device', 'email', 'phone', 'source', 'newslettersSubscribed'];

export const landingTemporaryUserCreatedFields: EventFields<LandingTemporaryUserCreated> = ['device', 'email', 'phone', 'source', 'newslettersSubscribed'];

export const landingHireSignUpFormSubmittedFields: EventFields<LandingHireSignUpFormSubmitted> = ['email', 'phone', 'source', 'newslettersSubscribed'];

export const landingUserSurveyStartedFields: EventFields<LandingUserSurveyStarted> = ['course', 'source', 'courseType', 'form_id'];

export const landingUserSurveyCompletedFields: EventFields<LandingUserSurveyCompleted> = ['course', 'source', 'courseType', 'form_id'];

export const landingUserSurveyQuestionChangedFields: EventFields<LandingUserSurveyQuestionChanged> = ['form_id', 'question_ref'];

export const landingUserSurveySuccessButtonClickedFields: EventFields<LandingUserSurveySuccessButtonClicked> = ['course', 'type'];

export const landingMonetaryBonusClickedFields: EventFields<LandingMonetaryBonusClicked> = ['currentStep'];

export const landingAgreementButtonClickedFields: EventFields<LandingAgreementButtonClicked> = ['courseType'];

export const landingAgreementEmailAddressSubmittedFields: EventFields<LandingAgreementEmailAddressSubmitted> = ['email'];

export const landingPrivatBankCashbackFields: EventFields<LandingPrivatBankCashback> = ['action', 'source'];

export const landingConsultationFormSubmittedFields: EventFields<LandingConsultationFormSubmitted> = ['course', 'source', 'userPhone', 'promoCodeSlug', 'leadScore'];

export const landingHireGraduateFormOpenedFields: EventFields<LandingHireGraduateFormOpened> = ['source'];

export const landingButtonClickCourseSelectionFields: EventFields<LandingButtonClickCourseSelection> = ['course', 'courseType'];

export const landingCourseLinkClickedFields: EventFields<LandingCourseLinkClicked> = ['course', 'courseType'];

export const landingPromoButtonClickedFields: EventFields<LandingPromoButtonClicked> = ['source'];

export const landingPromoBannerButtonClickedFields: EventFields<LandingPromoBannerButtonClicked> = ['source'];

export const landingButtonClickedFields: EventFields<LandingButtonClicked> = ['source', 'courseType'];

export const landingPlatformDemoCtaButtonClickedFields: EventFields<LandingPlatformDemoCtaButtonClicked> = ['section', 'courseSlug'];

export const landingResetPasswordFields: EventFields<LandingResetPassword> = ['action'];

export const landingUserDomainChangedFields: EventFields<LandingUserDomainChanged> = ['oldDomain', 'newDomain', 'source'];

export const landingTelegramPopupFields: EventFields<LandingTelegramPopup> = ['action'];

export const landingNonRelevantCountryPopupFields: EventFields<LandingNonRelevantCountryPopup> = ['action', 'selectedCountry'];

export const landingOpenTrialAccessFields: EventFields<LandingOpenTrialAccess> = ['status', 'message'];

export const landingPromoCodeAppliedFields: EventFields<LandingPromoCodeApplied> = ['slug', 'status', 'type', 'source'];

export const landingExitPopupOpenedFields: EventFields<LandingExitPopupOpened> = ['source'];

export const landingExitPopupClosedFields: EventFields<LandingExitPopupClosed> = ['source'];

export const landingWebChatOpenedFields: EventFields<LandingWebChatOpened> = [];

export const landingWebChatUserMessageFields: EventFields<LandingWebChatUserMessage> = [];

export const leaderboardUserXpEarnedForReplyInThreadFields: EventFields<LeaderboardUserXpEarnedForReplyInThread> = ['xp'];

export const learnVideoPlayedToPointFields: EventFields<LearnVideoPlayedToPoint> = ['videoType', 'originalVideoType', 'replayingPointInPercents', 'videoDurationInSeconds', 'courseSlug', 'topicSlug', 'videoTitle', 'videoId'];

export const linearLearningFlowOnboardingWasShownFields: EventFields<LinearLearningFlowOnboardingWasShown> = ['completed', 'topicSlug', 'moduleSlug'];

export const linearLearningFlowOpenedInMinimizedViewFields: EventFields<LinearLearningFlowOpenedInMinimizedView> = ['item', 'topicSlug', 'moduleSlug'];

export const lmsEditorModuleCreatedFromExistingFields: EventFields<LmsEditorModuleCreatedFromExisting> = ['professionSlug', 'newModuleSlug', 'addedDailyTasksCount', 'createdCourseTopicsCount', 'addedCourseTopicTasksCount', 'copiedCourseTopicTheoryFilesCount'];

export const lmsEditorProfessionCreatedFromExistingFields: EventFields<LmsEditorProfessionCreatedFromExisting> = ['templateProfessionSlug', 'newProfessionSlug', 'addedMorningTasksCount', 'addedLiveCodingTasksCount', 'addedAssignmentTasksCount'];

export const matchWorkingTimeContractorTimelineOpenedByMentorFields: EventFields<MatchWorkingTimeContractorTimelineOpenedByMentor> = [];

export const matchWorkingTimeIdleTimeCommentedFields: EventFields<MatchWorkingTimeIdleTimeCommented> = [];

export const mentorSolutionMentorSolutionOpenedFields: EventFields<MentorSolutionMentorSolutionOpened> = ['taskSlug', 'solutionType', 'hasBeenPassed'];

export const mobileAppNewVersionOnboardingOpenedFields: EventFields<MobileAppNewVersionOnboardingOpened> = ['action', 'appVersion'];

export const moduleRatingOpenFields: EventFields<ModuleRatingOpen> = ['rating', 'courseSlug', 'courseType', 'professionSlug'];

export const moduleRatingRateFields: EventFields<ModuleRatingRate> = ['rating', 'courseSlug', 'courseType', 'professionSlug'];

export const moduleRatingProceedToLogicTestButtonClickedFields: EventFields<ModuleRatingProceedToLogicTestButtonClicked> = ['rating', 'courseSlug', 'courseType', 'professionSlug'];

export const moduleRatingConsultationButtonClickedFields: EventFields<ModuleRatingConsultationButtonClicked> = ['rating', 'courseSlug', 'courseType', 'professionSlug'];

export const paymentUpdateMethodButtonClickedFields: EventFields<PaymentUpdateMethodButtonClicked> = ['subscriptionPlanName', 'subscriptionType'];

export const paymentMethodUpdatedFields: EventFields<PaymentMethodUpdated> = [];

export const paymentMethodCreatedFields: EventFields<PaymentMethodCreated> = ['provider', 'status'];

export const paymentRefundProcessedFields: EventFields<PaymentRefundProcessed> = ['provider', 'subscriptionPrice', 'subscriptionType', 'subscriptionPaymentType'];

export const paymentDisputedFields: EventFields<PaymentDisputed> = ['provider', 'subscriptionPlan', 'reason'];

export const paymentExclusiveSaleCreatedFields: EventFields<PaymentExclusiveSaleCreated> = [];

export const peerReviewStartFields: EventFields<PeerReviewStart> = ['id', 'testTaskAttemptId', 'testTaskId', 'reviewerTestTaskAttemptId', 'userId', 'source', 'status', 'reviewerId', 'reviewType'];

export const peerReviewReviewTaskFields: EventFields<PeerReviewReviewTask> = ['id', 'testTaskAttemptId', 'testTaskId', 'reviewerTestTaskAttemptId', 'userId', 'source', 'status', 'reviewerId', 'reviewType'];

export const peerReviewMovePendingPeerToPendingFields: EventFields<PeerReviewMovePendingPeerToPending> = ['id', 'testTaskAttemptId', 'testTaskId', 'reviewerTestTaskAttemptId', 'userId', 'source', 'status', 'reviewerId', 'reviewType'];

export const peerReviewExpireOnPeerReviewFields: EventFields<PeerReviewExpireOnPeerReview> = ['id', 'testTaskAttemptId', 'testTaskId', 'reviewerTestTaskAttemptId', 'userId', 'source', 'status', 'reviewerId', 'reviewType'];

export const peerReviewTaskReadyForReviewFields: EventFields<PeerReviewTaskReadyForReview> = ['id', 'testTaskAttemptId', 'testTaskId', 'reviewerTestTaskAttemptId', 'userId', 'source', 'status', 'reviewerId', 'reviewType'];

export const platformConsultationBecomeStudentButtonClickedFields: EventFields<PlatformConsultationBecomeStudentButtonClicked> = [];

export const platformConsultationGetConsultationButtonClickedFields: EventFields<PlatformConsultationGetConsultationButtonClicked> = [];

export const platformConsultationLeadRedirectedToFtApplicationFields: EventFields<PlatformConsultationLeadRedirectedToFtApplication> = [];

export const platformThemePreferenceChangedFields: EventFields<PlatformThemePreferenceChanged> = ['chosenTheme'];

export const platformThemePreferenceOnboardingCompletedFields: EventFields<PlatformThemePreferenceOnboardingCompleted> = ['chosenTheme'];

export const profileEmploymentProfileSubmittedFields: EventFields<ProfileEmploymentProfileSubmitted> = [];

export const reportProblemProblemReportFields: EventFields<ReportProblemProblemReport> = ['courseSlug', 'source'];

export const reportProblemRedirectToCannyFields: EventFields<ReportProblemRedirectToCanny> = ['courseSlug', 'source'];

export const streaksWidgetOpenedFields: EventFields<StreaksWidgetOpened> = [];

export const subscriptionPurchaseCompletedFields: EventFields<SubscriptionPurchaseCompleted> = ['isFirstSubscription', 'paymentMethod', 'pricingOptionSlug', 'pricingOptionName', 'subscriptionPlanName', 'subscriptionPrice', 'subscriptionDuration', 'subscriptionType', 'subscriptionPaymentType'];

export const subscriptionRecurringPurchaseProcessedFields: EventFields<SubscriptionRecurringPurchaseProcessed> = ['isFirstSubscription', 'paymentMethod', 'retryAttempt', 'status', 'pricingOptionSlug', 'pricingOptionName', 'subscriptionPlanName', 'subscriptionPrice', 'subscriptionDuration', 'subscriptionType', 'subscriptionPaymentType'];

export const subscriptionCancellationStartedFields: EventFields<SubscriptionCancellationStarted> = ['acceptedPaymentsCount', 'pricingOptionSlug', 'pricingOptionName', 'subscriptionPlanName', 'subscriptionPrice', 'subscriptionDuration', 'subscriptionType', 'subscriptionPaymentType'];

export const subscriptionCancellationFinishedFields: EventFields<SubscriptionCancellationFinished> = ['acceptedPaymentsCount', 'pricingOptionSlug', 'pricingOptionName', 'subscriptionPlanName', 'subscriptionPrice', 'subscriptionDuration', 'subscriptionType', 'subscriptionPaymentType'];

export const subscriptionCancellationPromoUsedFields: EventFields<SubscriptionCancellationPromoUsed> = ['acceptedPaymentsCount', 'pricingOptionSlug', 'pricingOptionName', 'subscriptionPlanName', 'subscriptionPrice', 'subscriptionDuration', 'subscriptionType', 'subscriptionPaymentType'];

export const subscriptionNextPaymentBlockShownFields: EventFields<SubscriptionNextPaymentBlockShown> = ['action'];

export const subscriptionNextPaymentCtaClickedFields: EventFields<SubscriptionNextPaymentCtaClicked> = ['action'];

export const subscriptionProductPaywallModalOpenedFields: EventFields<SubscriptionProductPaywallModalOpened> = ['paywallLocation'];

export const subscriptionProductCheckoutPayClickedFields: EventFields<SubscriptionProductCheckoutPayClicked> = ['paymentMethod', 'validationError'];

export const subscriptionProductLandingSignupFormViewFields: EventFields<SubscriptionProductLandingSignupFormView> = [];

export const subscriptionProductCheckoutEmailSubmittedFields: EventFields<SubscriptionProductCheckoutEmailSubmitted> = [];

export const subscriptionProductPaywallModalClosedFields: EventFields<SubscriptionProductPaywallModalClosed> = ['paywallLocation'];

export const subscriptionProductSubscriptionPlanSelectedFields: EventFields<SubscriptionProductSubscriptionPlanSelected> = ['pricingOptionName', 'pricingOptionSlug', 'source', 'paywallLocation', 'device', 'subscriptionPlanName', 'subscriptionPrice', 'subscriptionDuration', 'subscriptionType', 'subscriptionPaymentType'];

export const subscriptionProductPurchaseCompletedFields: EventFields<SubscriptionProductPurchaseCompleted> = ['paymentMethod', 'pricingOptionName', 'pricingOptionSlug', 'subscriptionPlanName', 'subscriptionPrice', 'subscriptionDuration', 'subscriptionType', 'subscriptionPaymentType'];

export const subscriptionProductTemporaryCredUserRedirectedToCheckoutFields: EventFields<SubscriptionProductTemporaryCredUserRedirectedToCheckout> = ['subscriptionPlanName', 'subscriptionPrice', 'subscriptionDuration', 'subscriptionType', 'subscriptionPaymentType'];

export const subscriptionProductTemporaryUserEmailUpdatedFields: EventFields<SubscriptionProductTemporaryUserEmailUpdated> = ['temporaryEmail', 'actualEmail'];

export const subscriptionProductCheckoutVisitFields: EventFields<SubscriptionProductCheckoutVisit> = ['registrationType', 'provider'];

export const subscriptionProductCheckoutErrorFields: EventFields<SubscriptionProductCheckoutError> = ['errorMessage', 'errorCode', 'emailError'];

export const subscriptionProductCheckoutClosedFields: EventFields<SubscriptionProductCheckoutClosed> = [];

export const subscriptionProductUserCreatedFields: EventFields<SubscriptionProductUserCreated> = ['redirectUrl'];

export const subscriptionProductPlatformPricingVisitFields: EventFields<SubscriptionProductPlatformPricingVisit> = [];

export const subscriptionProductPricingClosedFields: EventFields<SubscriptionProductPricingClosed> = [];

export const subscriptionProductDesktopEmailScreenShownFields: EventFields<SubscriptionProductDesktopEmailScreenShown> = [];

export const subscriptionProductDesktopEmailButtonClickedFields: EventFields<SubscriptionProductDesktopEmailButtonClicked> = ['button'];

export const subscriptionProductRenewSubscriptionButtonClickedFields: EventFields<SubscriptionProductRenewSubscriptionButtonClicked> = ['location'];

export const subscriptionProductQuizQuestionCompletedFields: EventFields<SubscriptionProductQuizQuestionCompleted> = ['form_id', 'question_ref'];

export const subscriptionProductExitOfferShownFields: EventFields<SubscriptionProductExitOfferShown> = [];

export const subscriptionProductExitOfferClosedFields: EventFields<SubscriptionProductExitOfferClosed> = [];

export const taskReviewTaskWasReviewedFields: EventFields<TaskReviewTaskWasReviewed> = ['id', 'testTaskAttemptId', 'testTaskId', 'reviewerTestTaskAttemptId', 'userId', 'source', 'status', 'reviewerId', 'reviewType', 'reviewerRole'];

export const taskReviewStartTaskReviewFields: EventFields<TaskReviewStartTaskReview> = ['id', 'testTaskAttemptId', 'testTaskId', 'reviewerTestTaskAttemptId', 'userId', 'source', 'status', 'reviewerId', 'reviewType', 'reviewerRole'];

export const taskReviewDeclinedByLinkAccessFields: EventFields<TaskReviewDeclinedByLinkAccess> = ['linkType'];

export const teamProjectJoinedTeamProjectFields: EventFields<TeamProjectJoinedTeamProject> = ['professionSlug'];

export const teamProjectLeftTeamProjectFields: EventFields<TeamProjectLeftTeamProject> = ['professionSlug'];

export const teamProjectStartedTeamProjectFields: EventFields<TeamProjectStartedTeamProject> = ['teamProjectId', 'professionSlug'];

export const teamProjectIntroPageOpenedFields: EventFields<TeamProjectIntroPageOpened> = ['step'];

export const teamProjectIntroPageJoinBtnClickedFields: EventFields<TeamProjectIntroPageJoinBtnClicked> = [];

export const teamProjectIntroPageLeaveBtnClickedFields: EventFields<TeamProjectIntroPageLeaveBtnClicked> = [];

export const teamProjectIntroPageGeneralChatBtnClickedFields: EventFields<TeamProjectIntroPageGeneralChatBtnClicked> = [];

export const teamProjectIntroPageGotoFirstTopicBtnClickedFields: EventFields<TeamProjectIntroPageGotoFirstTopicBtnClicked> = [];

export const teamProjectIntroPageTeamChatBtnClickedFields: EventFields<TeamProjectIntroPageTeamChatBtnClicked> = [];

export const testTaskFirstOpenedByStudentFields: EventFields<TestTaskFirstOpenedByStudent> = ['testTaskId', 'testTaskAttemptId', 'testTaskVersionId', 'programmingLanguage', 'flow', 'slug', 'testTaskType'];

export const testTaskFirstSuccessfulCheckByMentorFields: EventFields<TestTaskFirstSuccessfulCheckByMentor> = ['testTaskId', 'testTaskAttemptId', 'testTaskVersionId', 'programmingLanguage', 'flow', 'slug', 'testTaskType'];

export const testTaskActivityBannerClickedFields: EventFields<TestTaskActivityBannerClicked> = ['testTaskSlug', 'courseSlug', 'courseTopicSlug', 'programmingLanguage', 'flow', 'status'];

export const testTaskActivitySidebarUserProfileMiniatureClickedFields: EventFields<TestTaskActivitySidebarUserProfileMiniatureClicked> = ['section', 'position'];

export const testTaskActivitySidebarUserProfileDirectMessageClickedFields: EventFields<TestTaskActivitySidebarUserProfileDirectMessageClicked> = ['section', 'position'];

export const testTaskActivitySidebarSolvingNowSectionExpandedFields: EventFields<TestTaskActivitySidebarSolvingNowSectionExpanded> = [];

export const testTaskActivityUserTaskSolutionOnboardingFields: EventFields<TestTaskActivityUserTaskSolutionOnboarding> = ['status'];

export const testTaskActivityUserTaskSolutionOpenedFields: EventFields<TestTaskActivityUserTaskSolutionOpened> = [];

export const timezoneNewTimezoneAcceptedFields: EventFields<TimezoneNewTimezoneAccepted> = ['newTimeZone', 'oldTimeZone'];

export const timezoneNewTimezoneModalClosedFields: EventFields<TimezoneNewTimezoneModalClosed> = ['newTimeZone', 'oldTimeZone'];

export const userProfileOpenedFields: EventFields<UserProfileOpened> = ['pageUrl', 'opener'];

export const userProfileStatusUpdatedFields: EventFields<UserProfileStatusUpdated> = [];

export const userProfileEditClickedFields: EventFields<UserProfileEditClicked> = [];

export const userProfilePrivacySettingUpdatedFields: EventFields<UserProfilePrivacySettingUpdated> = ['value'];

export const userProfileUpdatedFields: EventFields<UserProfileUpdated> = [];

export const webinarSignUpFormSubmittedFields: EventFields<WebinarSignUpFormSubmitted> = ['webinar'];

export const webinarRegistrationWithAnotherAccountClickedFields: EventFields<WebinarRegistrationWithAnotherAccountClicked> = ['webinar'];

export interface AnalyticsClient {
  sendEvent(eventName: AnalyticsEventNames, eventData: AnalyticsEvents): Promise<void>;
}

export type AnalyticsEvents = FxLeadCreated
  | FxTopLeadCreated
  | FtLeadCreated
  | WebinarLeadCreated
  | MarathonLeadCreated
  | PlatformPromoIsShown
  | PlatformPromoIsAccepted
  | PurchaseFormSubmitted
  | BuyButtonClicked
  | BillingAddressFormSubmitted
  | SupportIconClicked
  | AiAssistantWidgetOpened
  | AiAssistantQuickActionButtonClicked
  | AiAssistantChatMessageSent
  | AiAssistantAiChatTriggered
  | AiReviewOnboardingCompleted
  | CalendarEventCanceled
  | CertificateEarned
  | CertificateDownloaded
  | CertificateShared
  | CertificatePageOpened
  | ChatDirectChatCreated
  | ChatGoToDirectChat
  | ChatOpenPracticePageChat
  | ChatMessageCreated
  | ChatOpenQuestionCreated
  | ChatPollCreated
  | ChatLoadMoreChats
  | ChatStudentTagUsed
  | ChatViewOpened
  | StudentCheckInFormOpened
  | StudentCheckInFormSent
  | CodeEditorAttemptCreated
  | EnglishLevelUpdated
  | EnglishRenewSubscriptionBtnClicked
  | EnglishPersonalLessonsBannerInteracted
  | PlatformExitPopUpAction
  | FulltimeFlowReApplyButtonClicked
  | GithubTaskPrOpened
  | GithubTaskPrReviewed
  | GithubTaskPrClosed
  | GroupDashboardPageOpened
  | GroupDashboardTaskSidebarOpened
  | GroupDashboardChatOpenFromDashboard
  | GroupDashboardHomeworkTaskOpenedForReview
  | LandingCourseAuthorLinkClicked
  | LandingReviewsCtaButtonClicked
  | LandingReviewButtonClicked
  | LandingSignUpFormView
  | LandingSignUpFormSubmitted
  | LandingTemporaryUserCreated
  | LandingHireSignUpFormSubmitted
  | LandingUserSurveyStarted
  | LandingUserSurveyCompleted
  | LandingUserSurveyQuestionChanged
  | LandingUserSurveySuccessButtonClicked
  | LandingMonetaryBonusClicked
  | LandingAgreementButtonClicked
  | LandingAgreementEmailAddressSubmitted
  | LandingPrivatBankCashback
  | LandingConsultationFormSubmitted
  | LandingHireGraduateFormOpened
  | LandingButtonClickCourseSelection
  | LandingCourseLinkClicked
  | LandingPromoButtonClicked
  | LandingPromoBannerButtonClicked
  | LandingButtonClicked
  | LandingPlatformDemoCtaButtonClicked
  | LandingResetPassword
  | LandingUserDomainChanged
  | LandingTelegramPopup
  | LandingNonRelevantCountryPopup
  | LandingOpenTrialAccess
  | LandingPromoCodeApplied
  | LandingExitPopupOpened
  | LandingExitPopupClosed
  | LandingWebChatOpened
  | LandingWebChatUserMessage
  | LeaderboardUserXpEarnedForReplyInThread
  | LearnVideoPlayedToPoint
  | LinearLearningFlowOnboardingWasShown
  | LinearLearningFlowOpenedInMinimizedView
  | LmsEditorModuleCreatedFromExisting
  | LmsEditorProfessionCreatedFromExisting
  | MatchWorkingTimeContractorTimelineOpenedByMentor
  | MatchWorkingTimeIdleTimeCommented
  | MentorSolutionMentorSolutionOpened
  | MobileAppNewVersionOnboardingOpened
  | ModuleRatingOpen
  | ModuleRatingRate
  | ModuleRatingProceedToLogicTestButtonClicked
  | ModuleRatingConsultationButtonClicked
  | PaymentUpdateMethodButtonClicked
  | PaymentMethodUpdated
  | PaymentMethodCreated
  | PaymentRefundProcessed
  | PaymentDisputed
  | PaymentExclusiveSaleCreated
  | PeerReviewStart
  | PeerReviewReviewTask
  | PeerReviewMovePendingPeerToPending
  | PeerReviewExpireOnPeerReview
  | PeerReviewTaskReadyForReview
  | PlatformConsultationBecomeStudentButtonClicked
  | PlatformConsultationGetConsultationButtonClicked
  | PlatformConsultationLeadRedirectedToFtApplication
  | PlatformThemePreferenceChanged
  | PlatformThemePreferenceOnboardingCompleted
  | ProfileEmploymentProfileSubmitted
  | ReportProblemProblemReport
  | ReportProblemRedirectToCanny
  | StreaksWidgetOpened
  | SubscriptionPurchaseCompleted
  | SubscriptionRecurringPurchaseProcessed
  | SubscriptionCancellationStarted
  | SubscriptionCancellationFinished
  | SubscriptionCancellationPromoUsed
  | SubscriptionNextPaymentBlockShown
  | SubscriptionNextPaymentCtaClicked
  | SubscriptionProductPaywallModalOpened
  | SubscriptionProductCheckoutPayClicked
  | SubscriptionProductLandingSignupFormView
  | SubscriptionProductCheckoutEmailSubmitted
  | SubscriptionProductPaywallModalClosed
  | SubscriptionProductSubscriptionPlanSelected
  | SubscriptionProductPurchaseCompleted
  | SubscriptionProductTemporaryCredUserRedirectedToCheckout
  | SubscriptionProductTemporaryUserEmailUpdated
  | SubscriptionProductCheckoutVisit
  | SubscriptionProductCheckoutError
  | SubscriptionProductCheckoutClosed
  | SubscriptionProductUserCreated
  | SubscriptionProductPlatformPricingVisit
  | SubscriptionProductPricingClosed
  | SubscriptionProductDesktopEmailScreenShown
  | SubscriptionProductDesktopEmailButtonClicked
  | SubscriptionProductRenewSubscriptionButtonClicked
  | SubscriptionProductQuizQuestionCompleted
  | SubscriptionProductExitOfferShown
  | SubscriptionProductExitOfferClosed
  | TaskReviewTaskWasReviewed
  | TaskReviewStartTaskReview
  | TaskReviewDeclinedByLinkAccess
  | TeamProjectJoinedTeamProject
  | TeamProjectLeftTeamProject
  | TeamProjectStartedTeamProject
  | TeamProjectIntroPageOpened
  | TeamProjectIntroPageJoinBtnClicked
  | TeamProjectIntroPageLeaveBtnClicked
  | TeamProjectIntroPageGeneralChatBtnClicked
  | TeamProjectIntroPageGotoFirstTopicBtnClicked
  | TeamProjectIntroPageTeamChatBtnClicked
  | TestTaskFirstOpenedByStudent
  | TestTaskFirstSuccessfulCheckByMentor
  | TestTaskActivityBannerClicked
  | TestTaskActivitySidebarUserProfileMiniatureClicked
  | TestTaskActivitySidebarUserProfileDirectMessageClicked
  | TestTaskActivitySidebarSolvingNowSectionExpanded
  | TestTaskActivityUserTaskSolutionOnboarding
  | TestTaskActivityUserTaskSolutionOpened
  | TimezoneNewTimezoneAccepted
  | TimezoneNewTimezoneModalClosed
  | UserProfileOpened
  | UserProfileStatusUpdated
  | UserProfileEditClicked
  | UserProfilePrivacySettingUpdated
  | UserProfileUpdated
  | WebinarSignUpFormSubmitted
  | WebinarRegistrationWithAnotherAccountClicked;

export type AnalyticsEventNames = 'fx_lead_created'
  | 'fx_top_lead_created'
  | 'ft_lead_created'
  | 'webinar_lead_created'
  | 'marathon_lead_created'
  | 'platform_promo_is_shown'
  | 'platform_promo_is_accepted'
  | 'purchase_form_submitted'
  | 'buy_button_clicked'
  | 'billing_address_form_submitted'
  | 'support_icon_clicked'
  | 'ai_assistant_widget_opened'
  | 'ai_assistant_quick_action_button_clicked'
  | 'ai_assistant_chat_message_sent'
  | 'ai_assistant_ai_chat_triggered'
  | 'ai_review_onboarding_completed'
  | 'calendar_event_canceled'
  | 'certificate_earned'
  | 'certificate_downloaded'
  | 'certificate_shared'
  | 'certificate_page_opened'
  | 'chat_direct_chat_created'
  | 'chat_go_to_direct_chat'
  | 'chat_open_practice_page_chat'
  | 'chat_message_created'
  | 'chat_open_question_created'
  | 'chat_poll_created'
  | 'chat_load_more_chats'
  | 'chat_student_tag_used'
  | 'chat_view_opened'
  | 'student_check_in_form_opened'
  | 'student_check_in_form_sent'
  | 'code_editor_attempt_created'
  | 'english_level_updated'
  | 'english_renew_subscription_btn_clicked'
  | 'english_personal_lessons_banner_interacted'
  | 'platform_exit_pop_up_action'
  | 'fulltime_flow_re_apply_button_clicked'
  | 'github_task_pr_opened'
  | 'github_task_pr_reviewed'
  | 'github_task_pr_closed'
  | 'group_dashboard_page_opened'
  | 'group_dashboard_task_sidebar_opened'
  | 'group_dashboard_chat_open_from_dashboard'
  | 'group_dashboard_homework_task_opened_for_review'
  | 'landing_course_author_link_clicked'
  | 'landing_reviews_cta_button_clicked'
  | 'landing_review_button_clicked'
  | 'landing_sign_up_form_view'
  | 'landing_sign_up_form_submitted'
  | 'landing_temporary_user_created'
  | 'landing_hire_sign_up_form_submitted'
  | 'landing_user_survey_started'
  | 'landing_user_survey_completed'
  | 'landing_user_survey_question_changed'
  | 'landing_user_survey_success_button_clicked'
  | 'landing_monetary_bonus_clicked'
  | 'landing_agreement_button_clicked'
  | 'landing_agreement_email_address_submitted'
  | 'landing_privat_bank_cashback'
  | 'landing_consultation_form_submitted'
  | 'landing_hire_graduate_form_opened'
  | 'landing_button_click_course_selection'
  | 'landing_course_link_clicked'
  | 'landing_promo_button_clicked'
  | 'landing_promo_banner_button_clicked'
  | 'landing_button_clicked'
  | 'landing_platform_demo_cta_button_clicked'
  | 'landing_reset_password'
  | 'landing_user_domain_changed'
  | 'landing_telegram_popup'
  | 'landing_non_relevant_country_popup'
  | 'landing_open_trial_access'
  | 'landing_promo_code_applied'
  | 'landing_exit_popup_opened'
  | 'landing_exit_popup_closed'
  | 'landing_web_chat_opened'
  | 'landing_web_chat_user_message'
  | 'leaderboard_user_xp_earned_for_reply_in_thread'
  | 'learn_video_played_to_point'
  | 'linear_learning_flow_onboarding_was_shown'
  | 'linear_learning_flow_opened_in_minimized_view'
  | 'lms_editor_module_created_from_existing'
  | 'lms_editor_profession_created_from_existing'
  | 'match_working_time_contractor_timeline_opened_by_mentor'
  | 'match_working_time_idle_time_commented'
  | 'mentor_solution_mentor_solution_opened'
  | 'mobile_app_new_version_onboarding_opened'
  | 'module_rating_open'
  | 'module_rating_rate'
  | 'module_rating_proceed_to_logic_test_button_clicked'
  | 'module_rating_consultation_button_clicked'
  | 'payment_update_method_button_clicked'
  | 'payment_method_updated'
  | 'payment_method_created'
  | 'payment_refund_processed'
  | 'payment_disputed'
  | 'payment_exclusive_sale_created'
  | 'peer_review_start'
  | 'peer_review_review_task'
  | 'peer_review_move_pending_peer_to_pending'
  | 'peer_review_expire_on_peer_review'
  | 'peer_review_task_ready_for_review'
  | 'platform_consultation_become_student_button_clicked'
  | 'platform_consultation_get_consultation_button_clicked'
  | 'platform_consultation_lead_redirected_to_ft_application'
  | 'platform_theme_preference_changed'
  | 'platform_theme_preference_onboarding_completed'
  | 'profile_employment_profile_submitted'
  | 'report_problem_problem_report'
  | 'report_problem_redirect_to_canny'
  | 'streaks_widget_opened'
  | 'subscription_purchase_completed'
  | 'subscription_recurring_purchase_processed'
  | 'subscription_cancellation_started'
  | 'subscription_cancellation_finished'
  | 'subscription_cancellation_promo_used'
  | 'subscription_next_payment_block_shown'
  | 'subscription_next_payment_cta_clicked'
  | 'subscription_product_paywall_modal_opened'
  | 'subscription_product_checkout_pay_clicked'
  | 'subscription_product_landing_signup_form_view'
  | 'subscription_product_checkout_email_submitted'
  | 'subscription_product_paywall_modal_closed'
  | 'subscription_product_subscription_plan_selected'
  | 'subscription_product_purchase_completed'
  | 'subscription_product_temporary_cred_user_redirected_to_checkout'
  | 'subscription_product_temporary_user_email_updated'
  | 'subscription_product_checkout_visit'
  | 'subscription_product_checkout_error'
  | 'subscription_product_checkout_closed'
  | 'subscription_product_user_created'
  | 'subscription_product_platform_pricing_visit'
  | 'subscription_product_pricing_closed'
  | 'subscription_product_desktop_email_screen_shown'
  | 'subscription_product_desktop_email_button_clicked'
  | 'subscription_product_renew_subscription_button_clicked'
  | 'subscription_product_quiz_question_completed'
  | 'subscription_product_exit_offer_shown'
  | 'subscription_product_exit_offer_closed'
  | 'task_review_task_was_reviewed'
  | 'task_review_start_task_review'
  | 'task_review_declined_by_link_access'
  | 'team_project_joined_team_project'
  | 'team_project_left_team_project'
  | 'team_project_started_team_project'
  | 'team_project_intro_page_opened'
  | 'team_project_intro_page_join_btn_clicked'
  | 'team_project_intro_page_leave_btn_clicked'
  | 'team_project_intro_page_general_chat_btn_clicked'
  | 'team_project_intro_page_goto_first_topic_btn_clicked'
  | 'team_project_intro_page_team_chat_btn_clicked'
  | 'test_task_first_opened_by_student'
  | 'test_task_first_successful_check_by_mentor'
  | 'test_task_activity_banner_clicked'
  | 'test_task_activity_sidebar_user_profile_miniature_clicked'
  | 'test_task_activity_sidebar_user_profile_direct_message_clicked'
  | 'test_task_activity_sidebar_solving_now_section_expanded'
  | 'test_task_activity_user_task_solution_onboarding'
  | 'test_task_activity_user_task_solution_opened'
  | 'timezone_new_timezone_accepted'
  | 'timezone_new_timezone_modal_closed'
  | 'user_profile_opened'
  | 'user_profile_status_updated'
  | 'user_profile_edit_clicked'
  | 'user_profile_privacy_setting_updated'
  | 'user_profile_updated'
  | 'webinar_sign_up_form_submitted'
  | 'webinar_registration_with_another_account_clicked';

export class AnalyticsSDK {
  client: AnalyticsClient;

  filterEventFields = <E extends AnalyticsEvents>(event: E, fields?: EventFields<E>): E => {
    if (!fields) {
      return event;
    }

    return fields.reduce((acc, field) => ({
      ...acc,
      ...(
        field in event
          ? { [field]: event[field] }
          : {}
      ),
    }), {} as E);
  };

  global = {
    sendFxLeadCreatedEvent: (event: FxLeadCreated): Promise<void> => {
      return this.client.sendEvent(
        'fx_lead_created',
        this.filterEventFields(event, fxLeadCreatedFields),
      );
    },
    sendFxTopLeadCreatedEvent: (event: FxTopLeadCreated): Promise<void> => {
      return this.client.sendEvent(
        'fx_top_lead_created',
        this.filterEventFields(event, fxTopLeadCreatedFields),
      );
    },
    sendFtLeadCreatedEvent: (event: FtLeadCreated): Promise<void> => {
      return this.client.sendEvent(
        'ft_lead_created',
        this.filterEventFields(event, ftLeadCreatedFields),
      );
    },
    sendWebinarLeadCreatedEvent: (event: WebinarLeadCreated): Promise<void> => {
      return this.client.sendEvent(
        'webinar_lead_created',
        this.filterEventFields(event, webinarLeadCreatedFields),
      );
    },
    sendMarathonLeadCreatedEvent: (event: MarathonLeadCreated): Promise<void> => {
      return this.client.sendEvent(
        'marathon_lead_created',
        this.filterEventFields(event, marathonLeadCreatedFields),
      );
    },
    sendPlatformPromoIsShownEvent: (event: PlatformPromoIsShown): Promise<void> => {
      return this.client.sendEvent(
        'platform_promo_is_shown',
        this.filterEventFields(event, platformPromoIsShownFields),
      );
    },
    sendPlatformPromoIsAcceptedEvent: (event: PlatformPromoIsAccepted): Promise<void> => {
      return this.client.sendEvent(
        'platform_promo_is_accepted',
        this.filterEventFields(event, platformPromoIsAcceptedFields),
      );
    },
    sendPurchaseFormSubmittedEvent: (event: PurchaseFormSubmitted): Promise<void> => {
      return this.client.sendEvent(
        'purchase_form_submitted',
        this.filterEventFields(event, purchaseFormSubmittedFields),
      );
    },
    sendBuyButtonClickedEvent: (event: BuyButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'buy_button_clicked',
        this.filterEventFields(event, buyButtonClickedFields),
      );
    },
    sendBillingAddressFormSubmittedEvent: (event: BillingAddressFormSubmitted): Promise<void> => {
      return this.client.sendEvent(
        'billing_address_form_submitted',
        this.filterEventFields(event, billingAddressFormSubmittedFields),
      );
    },
    sendSupportIconClickedEvent: (event: SupportIconClicked): Promise<void> => {
      return this.client.sendEvent(
        'support_icon_clicked',
        this.filterEventFields(event, supportIconClickedFields),
      );
    },
  };

  aiAssistant = {
    sendWidgetOpenedEvent: (event: AiAssistantWidgetOpened): Promise<void> => {
      return this.client.sendEvent(
        'ai_assistant_widget_opened',
        this.filterEventFields(event, aiAssistantWidgetOpenedFields),
      );
    },
    sendQuickActionButtonClickedEvent: (event: AiAssistantQuickActionButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'ai_assistant_quick_action_button_clicked',
        this.filterEventFields(event, aiAssistantQuickActionButtonClickedFields),
      );
    },
    sendChatMessageSentEvent: (event: AiAssistantChatMessageSent): Promise<void> => {
      return this.client.sendEvent(
        'ai_assistant_chat_message_sent',
        this.filterEventFields(event, aiAssistantChatMessageSentFields),
      );
    },
    sendAiChatTriggeredEvent: (event: AiAssistantAiChatTriggered): Promise<void> => {
      return this.client.sendEvent(
        'ai_assistant_ai_chat_triggered',
        this.filterEventFields(event, aiAssistantAiChatTriggeredFields),
      );
    },
  };

  aiReview = {
    sendOnboardingCompletedEvent: (event: AiReviewOnboardingCompleted): Promise<void> => {
      return this.client.sendEvent(
        'ai_review_onboarding_completed',
        this.filterEventFields(event, aiReviewOnboardingCompletedFields),
      );
    },
  };

  calendar = {
    sendEventCanceledEvent: (event: CalendarEventCanceled): Promise<void> => {
      return this.client.sendEvent(
        'calendar_event_canceled',
        this.filterEventFields(event, calendarEventCanceledFields),
      );
    },
  };

  certificate = {
    sendEarnedEvent: (event: CertificateEarned): Promise<void> => {
      return this.client.sendEvent(
        'certificate_earned',
        this.filterEventFields(event, certificateEarnedFields),
      );
    },
    sendDownloadedEvent: (event: CertificateDownloaded): Promise<void> => {
      return this.client.sendEvent(
        'certificate_downloaded',
        this.filterEventFields(event, certificateDownloadedFields),
      );
    },
    sendSharedEvent: (event: CertificateShared): Promise<void> => {
      return this.client.sendEvent(
        'certificate_shared',
        this.filterEventFields(event, certificateSharedFields),
      );
    },
    sendPageOpenedEvent: (event: CertificatePageOpened): Promise<void> => {
      return this.client.sendEvent(
        'certificate_page_opened',
        this.filterEventFields(event, certificatePageOpenedFields),
      );
    },
  };

  chat = {
    sendDirectChatCreatedEvent: (event: ChatDirectChatCreated): Promise<void> => {
      return this.client.sendEvent(
        'chat_direct_chat_created',
        this.filterEventFields(event, chatDirectChatCreatedFields),
      );
    },
    sendGoToDirectChatEvent: (event: ChatGoToDirectChat): Promise<void> => {
      return this.client.sendEvent(
        'chat_go_to_direct_chat',
        this.filterEventFields(event, chatGoToDirectChatFields),
      );
    },
    sendOpenPracticePageChatEvent: (event: ChatOpenPracticePageChat): Promise<void> => {
      return this.client.sendEvent(
        'chat_open_practice_page_chat',
        this.filterEventFields(event, chatOpenPracticePageChatFields),
      );
    },
    sendMessageCreatedEvent: (event: ChatMessageCreated): Promise<void> => {
      return this.client.sendEvent(
        'chat_message_created',
        this.filterEventFields(event, chatMessageCreatedFields),
      );
    },
    sendOpenQuestionCreatedEvent: (event: ChatOpenQuestionCreated): Promise<void> => {
      return this.client.sendEvent(
        'chat_open_question_created',
        this.filterEventFields(event, chatOpenQuestionCreatedFields),
      );
    },
    sendPollCreatedEvent: (event: ChatPollCreated): Promise<void> => {
      return this.client.sendEvent(
        'chat_poll_created',
        this.filterEventFields(event, chatPollCreatedFields),
      );
    },
    sendLoadMoreChatsEvent: (event: ChatLoadMoreChats): Promise<void> => {
      return this.client.sendEvent(
        'chat_load_more_chats',
        this.filterEventFields(event, chatLoadMoreChatsFields),
      );
    },
    sendStudentTagUsedEvent: (event: ChatStudentTagUsed): Promise<void> => {
      return this.client.sendEvent(
        'chat_student_tag_used',
        this.filterEventFields(event, chatStudentTagUsedFields),
      );
    },
    sendViewOpenedEvent: (event: ChatViewOpened): Promise<void> => {
      return this.client.sendEvent(
        'chat_view_opened',
        this.filterEventFields(event, chatViewOpenedFields),
      );
    },
  };

  studentCheckIn = {
    sendFormOpenedEvent: (event: StudentCheckInFormOpened): Promise<void> => {
      return this.client.sendEvent(
        'student_check_in_form_opened',
        this.filterEventFields(event, studentCheckInFormOpenedFields),
      );
    },
    sendFormSentEvent: (event: StudentCheckInFormSent): Promise<void> => {
      return this.client.sendEvent(
        'student_check_in_form_sent',
        this.filterEventFields(event, studentCheckInFormSentFields),
      );
    },
  };

  codeEditor = {
    sendAttemptCreatedEvent: (event: CodeEditorAttemptCreated): Promise<void> => {
      return this.client.sendEvent(
        'code_editor_attempt_created',
        this.filterEventFields(event, codeEditorAttemptCreatedFields),
      );
    },
  };

  english = {
    sendLevelUpdatedEvent: (event: EnglishLevelUpdated): Promise<void> => {
      return this.client.sendEvent(
        'english_level_updated',
        this.filterEventFields(event, englishLevelUpdatedFields),
      );
    },
    sendRenewSubscriptionBtnClickedEvent: (event: EnglishRenewSubscriptionBtnClicked): Promise<void> => {
      return this.client.sendEvent(
        'english_renew_subscription_btn_clicked',
        this.filterEventFields(event, englishRenewSubscriptionBtnClickedFields),
      );
    },
    sendPersonalLessonsBannerInteractedEvent: (event: EnglishPersonalLessonsBannerInteracted): Promise<void> => {
      return this.client.sendEvent(
        'english_personal_lessons_banner_interacted',
        this.filterEventFields(event, englishPersonalLessonsBannerInteractedFields),
      );
    },
  };

  platformExitPopUp = {
    sendActionEvent: (event: PlatformExitPopUpAction): Promise<void> => {
      return this.client.sendEvent(
        'platform_exit_pop_up_action',
        this.filterEventFields(event, platformExitPopUpActionFields),
      );
    },
  };

  fulltimeFlow = {
    sendReApplyButtonClickedEvent: (event: FulltimeFlowReApplyButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'fulltime_flow_re_apply_button_clicked',
        this.filterEventFields(event, fulltimeFlowReApplyButtonClickedFields),
      );
    },
  };

  githubTask = {
    sendPrOpenedEvent: (event: GithubTaskPrOpened): Promise<void> => {
      return this.client.sendEvent(
        'github_task_pr_opened',
        this.filterEventFields(event, githubTaskPrOpenedFields),
      );
    },
    sendPrReviewedEvent: (event: GithubTaskPrReviewed): Promise<void> => {
      return this.client.sendEvent(
        'github_task_pr_reviewed',
        this.filterEventFields(event, githubTaskPrReviewedFields),
      );
    },
    sendPrClosedEvent: (event: GithubTaskPrClosed): Promise<void> => {
      return this.client.sendEvent(
        'github_task_pr_closed',
        this.filterEventFields(event, githubTaskPrClosedFields),
      );
    },
  };

  groupDashboard = {
    sendPageOpenedEvent: (event: GroupDashboardPageOpened): Promise<void> => {
      return this.client.sendEvent(
        'group_dashboard_page_opened',
        this.filterEventFields(event, groupDashboardPageOpenedFields),
      );
    },
    sendTaskSidebarOpenedEvent: (event: GroupDashboardTaskSidebarOpened): Promise<void> => {
      return this.client.sendEvent(
        'group_dashboard_task_sidebar_opened',
        this.filterEventFields(event, groupDashboardTaskSidebarOpenedFields),
      );
    },
    sendChatOpenFromDashboardEvent: (event: GroupDashboardChatOpenFromDashboard): Promise<void> => {
      return this.client.sendEvent(
        'group_dashboard_chat_open_from_dashboard',
        this.filterEventFields(event, groupDashboardChatOpenFromDashboardFields),
      );
    },
    sendHomeworkTaskOpenedForReviewEvent: (event: GroupDashboardHomeworkTaskOpenedForReview): Promise<void> => {
      return this.client.sendEvent(
        'group_dashboard_homework_task_opened_for_review',
        this.filterEventFields(event, groupDashboardHomeworkTaskOpenedForReviewFields),
      );
    },
  };

  landing = {
    sendCourseAuthorLinkClickedEvent: (event: LandingCourseAuthorLinkClicked): Promise<void> => {
      return this.client.sendEvent(
        'landing_course_author_link_clicked',
        this.filterEventFields(event, landingCourseAuthorLinkClickedFields),
      );
    },
    sendReviewsCtaButtonClickedEvent: (event: LandingReviewsCtaButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'landing_reviews_cta_button_clicked',
        this.filterEventFields(event, landingReviewsCtaButtonClickedFields),
      );
    },
    sendReviewButtonClickedEvent: (event: LandingReviewButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'landing_review_button_clicked',
        this.filterEventFields(event, landingReviewButtonClickedFields),
      );
    },
    sendSignUpFormViewEvent: (event: LandingSignUpFormView): Promise<void> => {
      return this.client.sendEvent(
        'landing_sign_up_form_view',
        this.filterEventFields(event, landingSignUpFormViewFields),
      );
    },
    sendSignUpFormSubmittedEvent: (event: LandingSignUpFormSubmitted): Promise<void> => {
      return this.client.sendEvent(
        'landing_sign_up_form_submitted',
        this.filterEventFields(event, landingSignUpFormSubmittedFields),
      );
    },
    sendTemporaryUserCreatedEvent: (event: LandingTemporaryUserCreated): Promise<void> => {
      return this.client.sendEvent(
        'landing_temporary_user_created',
        this.filterEventFields(event, landingTemporaryUserCreatedFields),
      );
    },
    sendHireSignUpFormSubmittedEvent: (event: LandingHireSignUpFormSubmitted): Promise<void> => {
      return this.client.sendEvent(
        'landing_hire_sign_up_form_submitted',
        this.filterEventFields(event, landingHireSignUpFormSubmittedFields),
      );
    },
    sendUserSurveyStartedEvent: (event: LandingUserSurveyStarted): Promise<void> => {
      return this.client.sendEvent(
        'landing_user_survey_started',
        this.filterEventFields(event, landingUserSurveyStartedFields),
      );
    },
    sendUserSurveyCompletedEvent: (event: LandingUserSurveyCompleted): Promise<void> => {
      return this.client.sendEvent(
        'landing_user_survey_completed',
        this.filterEventFields(event, landingUserSurveyCompletedFields),
      );
    },
    sendUserSurveyQuestionChangedEvent: (event: LandingUserSurveyQuestionChanged): Promise<void> => {
      return this.client.sendEvent(
        'landing_user_survey_question_changed',
        this.filterEventFields(event, landingUserSurveyQuestionChangedFields),
      );
    },
    sendUserSurveySuccessButtonClickedEvent: (event: LandingUserSurveySuccessButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'landing_user_survey_success_button_clicked',
        this.filterEventFields(event, landingUserSurveySuccessButtonClickedFields),
      );
    },
    sendMonetaryBonusClickedEvent: (event: LandingMonetaryBonusClicked): Promise<void> => {
      return this.client.sendEvent(
        'landing_monetary_bonus_clicked',
        this.filterEventFields(event, landingMonetaryBonusClickedFields),
      );
    },
    sendAgreementButtonClickedEvent: (event: LandingAgreementButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'landing_agreement_button_clicked',
        this.filterEventFields(event, landingAgreementButtonClickedFields),
      );
    },
    sendAgreementEmailAddressSubmittedEvent: (event: LandingAgreementEmailAddressSubmitted): Promise<void> => {
      return this.client.sendEvent(
        'landing_agreement_email_address_submitted',
        this.filterEventFields(event, landingAgreementEmailAddressSubmittedFields),
      );
    },
    sendPrivatBankCashbackEvent: (event: LandingPrivatBankCashback): Promise<void> => {
      return this.client.sendEvent(
        'landing_privat_bank_cashback',
        this.filterEventFields(event, landingPrivatBankCashbackFields),
      );
    },
    sendConsultationFormSubmittedEvent: (event: LandingConsultationFormSubmitted): Promise<void> => {
      return this.client.sendEvent(
        'landing_consultation_form_submitted',
        this.filterEventFields(event, landingConsultationFormSubmittedFields),
      );
    },
    sendHireGraduateFormOpenedEvent: (event: LandingHireGraduateFormOpened): Promise<void> => {
      return this.client.sendEvent(
        'landing_hire_graduate_form_opened',
        this.filterEventFields(event, landingHireGraduateFormOpenedFields),
      );
    },
    sendButtonClickCourseSelectionEvent: (event: LandingButtonClickCourseSelection): Promise<void> => {
      return this.client.sendEvent(
        'landing_button_click_course_selection',
        this.filterEventFields(event, landingButtonClickCourseSelectionFields),
      );
    },
    sendCourseLinkClickedEvent: (event: LandingCourseLinkClicked): Promise<void> => {
      return this.client.sendEvent(
        'landing_course_link_clicked',
        this.filterEventFields(event, landingCourseLinkClickedFields),
      );
    },
    sendPromoButtonClickedEvent: (event: LandingPromoButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'landing_promo_button_clicked',
        this.filterEventFields(event, landingPromoButtonClickedFields),
      );
    },
    sendPromoBannerButtonClickedEvent: (event: LandingPromoBannerButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'landing_promo_banner_button_clicked',
        this.filterEventFields(event, landingPromoBannerButtonClickedFields),
      );
    },
    sendButtonClickedEvent: (event: LandingButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'landing_button_clicked',
        this.filterEventFields(event, landingButtonClickedFields),
      );
    },
    sendPlatformDemoCtaButtonClickedEvent: (event: LandingPlatformDemoCtaButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'landing_platform_demo_cta_button_clicked',
        this.filterEventFields(event, landingPlatformDemoCtaButtonClickedFields),
      );
    },
    sendResetPasswordEvent: (event: LandingResetPassword): Promise<void> => {
      return this.client.sendEvent(
        'landing_reset_password',
        this.filterEventFields(event, landingResetPasswordFields),
      );
    },
    sendUserDomainChangedEvent: (event: LandingUserDomainChanged): Promise<void> => {
      return this.client.sendEvent(
        'landing_user_domain_changed',
        this.filterEventFields(event, landingUserDomainChangedFields),
      );
    },
    sendTelegramPopupEvent: (event: LandingTelegramPopup): Promise<void> => {
      return this.client.sendEvent(
        'landing_telegram_popup',
        this.filterEventFields(event, landingTelegramPopupFields),
      );
    },
    sendNonRelevantCountryPopupEvent: (event: LandingNonRelevantCountryPopup): Promise<void> => {
      return this.client.sendEvent(
        'landing_non_relevant_country_popup',
        this.filterEventFields(event, landingNonRelevantCountryPopupFields),
      );
    },
    sendOpenTrialAccessEvent: (event: LandingOpenTrialAccess): Promise<void> => {
      return this.client.sendEvent(
        'landing_open_trial_access',
        this.filterEventFields(event, landingOpenTrialAccessFields),
      );
    },
    sendPromoCodeAppliedEvent: (event: LandingPromoCodeApplied): Promise<void> => {
      return this.client.sendEvent(
        'landing_promo_code_applied',
        this.filterEventFields(event, landingPromoCodeAppliedFields),
      );
    },
    sendExitPopupOpenedEvent: (event: LandingExitPopupOpened): Promise<void> => {
      return this.client.sendEvent(
        'landing_exit_popup_opened',
        this.filterEventFields(event, landingExitPopupOpenedFields),
      );
    },
    sendExitPopupClosedEvent: (event: LandingExitPopupClosed): Promise<void> => {
      return this.client.sendEvent(
        'landing_exit_popup_closed',
        this.filterEventFields(event, landingExitPopupClosedFields),
      );
    },
    sendWebChatOpenedEvent: (event: LandingWebChatOpened): Promise<void> => {
      return this.client.sendEvent(
        'landing_web_chat_opened',
        this.filterEventFields(event, landingWebChatOpenedFields),
      );
    },
    sendWebChatUserMessageEvent: (event: LandingWebChatUserMessage): Promise<void> => {
      return this.client.sendEvent(
        'landing_web_chat_user_message',
        this.filterEventFields(event, landingWebChatUserMessageFields),
      );
    },
  };

  leaderboardUser = {
    sendXpEarnedForReplyInThreadEvent: (event: LeaderboardUserXpEarnedForReplyInThread): Promise<void> => {
      return this.client.sendEvent(
        'leaderboard_user_xp_earned_for_reply_in_thread',
        this.filterEventFields(event, leaderboardUserXpEarnedForReplyInThreadFields),
      );
    },
  };

  learn = {
    sendVideoPlayedToPointEvent: (event: LearnVideoPlayedToPoint): Promise<void> => {
      return this.client.sendEvent(
        'learn_video_played_to_point',
        this.filterEventFields(event, learnVideoPlayedToPointFields),
      );
    },
  };

  linearLearningFlow = {
    sendOnboardingWasShownEvent: (event: LinearLearningFlowOnboardingWasShown): Promise<void> => {
      return this.client.sendEvent(
        'linear_learning_flow_onboarding_was_shown',
        this.filterEventFields(event, linearLearningFlowOnboardingWasShownFields),
      );
    },
    sendOpenedInMinimizedViewEvent: (event: LinearLearningFlowOpenedInMinimizedView): Promise<void> => {
      return this.client.sendEvent(
        'linear_learning_flow_opened_in_minimized_view',
        this.filterEventFields(event, linearLearningFlowOpenedInMinimizedViewFields),
      );
    },
  };

  lmsEditor = {
    sendModuleCreatedFromExistingEvent: (event: LmsEditorModuleCreatedFromExisting): Promise<void> => {
      return this.client.sendEvent(
        'lms_editor_module_created_from_existing',
        this.filterEventFields(event, lmsEditorModuleCreatedFromExistingFields),
      );
    },
    sendProfessionCreatedFromExistingEvent: (event: LmsEditorProfessionCreatedFromExisting): Promise<void> => {
      return this.client.sendEvent(
        'lms_editor_profession_created_from_existing',
        this.filterEventFields(event, lmsEditorProfessionCreatedFromExistingFields),
      );
    },
  };

  matchWorkingTime = {
    sendContractorTimelineOpenedByMentorEvent: (event: MatchWorkingTimeContractorTimelineOpenedByMentor): Promise<void> => {
      return this.client.sendEvent(
        'match_working_time_contractor_timeline_opened_by_mentor',
        this.filterEventFields(event, matchWorkingTimeContractorTimelineOpenedByMentorFields),
      );
    },
    sendIdleTimeCommentedEvent: (event: MatchWorkingTimeIdleTimeCommented): Promise<void> => {
      return this.client.sendEvent(
        'match_working_time_idle_time_commented',
        this.filterEventFields(event, matchWorkingTimeIdleTimeCommentedFields),
      );
    },
  };

  mentorSolution = {
    sendMentorSolutionOpenedEvent: (event: MentorSolutionMentorSolutionOpened): Promise<void> => {
      return this.client.sendEvent(
        'mentor_solution_mentor_solution_opened',
        this.filterEventFields(event, mentorSolutionMentorSolutionOpenedFields),
      );
    },
  };

  mobileApp = {
    sendNewVersionOnboardingOpenedEvent: (event: MobileAppNewVersionOnboardingOpened): Promise<void> => {
      return this.client.sendEvent(
        'mobile_app_new_version_onboarding_opened',
        this.filterEventFields(event, mobileAppNewVersionOnboardingOpenedFields),
      );
    },
  };

  moduleRating = {
    sendOpenEvent: (event: ModuleRatingOpen): Promise<void> => {
      return this.client.sendEvent(
        'module_rating_open',
        this.filterEventFields(event, moduleRatingOpenFields),
      );
    },
    sendRateEvent: (event: ModuleRatingRate): Promise<void> => {
      return this.client.sendEvent(
        'module_rating_rate',
        this.filterEventFields(event, moduleRatingRateFields),
      );
    },
    sendProceedToLogicTestButtonClickedEvent: (event: ModuleRatingProceedToLogicTestButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'module_rating_proceed_to_logic_test_button_clicked',
        this.filterEventFields(event, moduleRatingProceedToLogicTestButtonClickedFields),
      );
    },
    sendConsultationButtonClickedEvent: (event: ModuleRatingConsultationButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'module_rating_consultation_button_clicked',
        this.filterEventFields(event, moduleRatingConsultationButtonClickedFields),
      );
    },
  };

  payment = {
    sendUpdateMethodButtonClickedEvent: (event: PaymentUpdateMethodButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'payment_update_method_button_clicked',
        this.filterEventFields(event, paymentUpdateMethodButtonClickedFields),
      );
    },
    sendMethodUpdatedEvent: (event: PaymentMethodUpdated): Promise<void> => {
      return this.client.sendEvent(
        'payment_method_updated',
        this.filterEventFields(event, paymentMethodUpdatedFields),
      );
    },
    sendMethodCreatedEvent: (event: PaymentMethodCreated): Promise<void> => {
      return this.client.sendEvent(
        'payment_method_created',
        this.filterEventFields(event, paymentMethodCreatedFields),
      );
    },
    sendRefundProcessedEvent: (event: PaymentRefundProcessed): Promise<void> => {
      return this.client.sendEvent(
        'payment_refund_processed',
        this.filterEventFields(event, paymentRefundProcessedFields),
      );
    },
    sendDisputedEvent: (event: PaymentDisputed): Promise<void> => {
      return this.client.sendEvent(
        'payment_disputed',
        this.filterEventFields(event, paymentDisputedFields),
      );
    },
    sendExclusiveSaleCreatedEvent: (event: PaymentExclusiveSaleCreated): Promise<void> => {
      return this.client.sendEvent(
        'payment_exclusive_sale_created',
        this.filterEventFields(event, paymentExclusiveSaleCreatedFields),
      );
    },
  };

  peerReview = {
    sendStartEvent: (event: PeerReviewStart): Promise<void> => {
      return this.client.sendEvent(
        'peer_review_start',
        this.filterEventFields(event, peerReviewStartFields),
      );
    },
    sendReviewTaskEvent: (event: PeerReviewReviewTask): Promise<void> => {
      return this.client.sendEvent(
        'peer_review_review_task',
        this.filterEventFields(event, peerReviewReviewTaskFields),
      );
    },
    sendMovePendingPeerToPendingEvent: (event: PeerReviewMovePendingPeerToPending): Promise<void> => {
      return this.client.sendEvent(
        'peer_review_move_pending_peer_to_pending',
        this.filterEventFields(event, peerReviewMovePendingPeerToPendingFields),
      );
    },
    sendExpireOnPeerReviewEvent: (event: PeerReviewExpireOnPeerReview): Promise<void> => {
      return this.client.sendEvent(
        'peer_review_expire_on_peer_review',
        this.filterEventFields(event, peerReviewExpireOnPeerReviewFields),
      );
    },
    sendTaskReadyForReviewEvent: (event: PeerReviewTaskReadyForReview): Promise<void> => {
      return this.client.sendEvent(
        'peer_review_task_ready_for_review',
        this.filterEventFields(event, peerReviewTaskReadyForReviewFields),
      );
    },
  };

  platformConsultation = {
    sendBecomeStudentButtonClickedEvent: (event: PlatformConsultationBecomeStudentButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'platform_consultation_become_student_button_clicked',
        this.filterEventFields(event, platformConsultationBecomeStudentButtonClickedFields),
      );
    },
    sendGetConsultationButtonClickedEvent: (event: PlatformConsultationGetConsultationButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'platform_consultation_get_consultation_button_clicked',
        this.filterEventFields(event, platformConsultationGetConsultationButtonClickedFields),
      );
    },
    sendLeadRedirectedToFtApplicationEvent: (event: PlatformConsultationLeadRedirectedToFtApplication): Promise<void> => {
      return this.client.sendEvent(
        'platform_consultation_lead_redirected_to_ft_application',
        this.filterEventFields(event, platformConsultationLeadRedirectedToFtApplicationFields),
      );
    },
  };

  platformThemePreference = {
    sendChangedEvent: (event: PlatformThemePreferenceChanged): Promise<void> => {
      return this.client.sendEvent(
        'platform_theme_preference_changed',
        this.filterEventFields(event, platformThemePreferenceChangedFields),
      );
    },
    sendOnboardingCompletedEvent: (event: PlatformThemePreferenceOnboardingCompleted): Promise<void> => {
      return this.client.sendEvent(
        'platform_theme_preference_onboarding_completed',
        this.filterEventFields(event, platformThemePreferenceOnboardingCompletedFields),
      );
    },
  };

  profile = {
    sendEmploymentProfileSubmittedEvent: (event: ProfileEmploymentProfileSubmitted): Promise<void> => {
      return this.client.sendEvent(
        'profile_employment_profile_submitted',
        this.filterEventFields(event, profileEmploymentProfileSubmittedFields),
      );
    },
  };

  reportProblem = {
    sendProblemReportEvent: (event: ReportProblemProblemReport): Promise<void> => {
      return this.client.sendEvent(
        'report_problem_problem_report',
        this.filterEventFields(event, reportProblemProblemReportFields),
      );
    },
    sendRedirectToCannyEvent: (event: ReportProblemRedirectToCanny): Promise<void> => {
      return this.client.sendEvent(
        'report_problem_redirect_to_canny',
        this.filterEventFields(event, reportProblemRedirectToCannyFields),
      );
    },
  };

  streaks = {
    sendWidgetOpenedEvent: (event: StreaksWidgetOpened): Promise<void> => {
      return this.client.sendEvent(
        'streaks_widget_opened',
        this.filterEventFields(event, streaksWidgetOpenedFields),
      );
    },
  };

  subscription = {
    sendPurchaseCompletedEvent: (event: SubscriptionPurchaseCompleted): Promise<void> => {
      return this.client.sendEvent(
        'subscription_purchase_completed',
        this.filterEventFields(event, subscriptionPurchaseCompletedFields),
      );
    },
    sendRecurringPurchaseProcessedEvent: (event: SubscriptionRecurringPurchaseProcessed): Promise<void> => {
      return this.client.sendEvent(
        'subscription_recurring_purchase_processed',
        this.filterEventFields(event, subscriptionRecurringPurchaseProcessedFields),
      );
    },
    sendCancellationStartedEvent: (event: SubscriptionCancellationStarted): Promise<void> => {
      return this.client.sendEvent(
        'subscription_cancellation_started',
        this.filterEventFields(event, subscriptionCancellationStartedFields),
      );
    },
    sendCancellationFinishedEvent: (event: SubscriptionCancellationFinished): Promise<void> => {
      return this.client.sendEvent(
        'subscription_cancellation_finished',
        this.filterEventFields(event, subscriptionCancellationFinishedFields),
      );
    },
    sendCancellationPromoUsedEvent: (event: SubscriptionCancellationPromoUsed): Promise<void> => {
      return this.client.sendEvent(
        'subscription_cancellation_promo_used',
        this.filterEventFields(event, subscriptionCancellationPromoUsedFields),
      );
    },
    sendNextPaymentBlockShownEvent: (event: SubscriptionNextPaymentBlockShown): Promise<void> => {
      return this.client.sendEvent(
        'subscription_next_payment_block_shown',
        this.filterEventFields(event, subscriptionNextPaymentBlockShownFields),
      );
    },
    sendNextPaymentCtaClickedEvent: (event: SubscriptionNextPaymentCtaClicked): Promise<void> => {
      return this.client.sendEvent(
        'subscription_next_payment_cta_clicked',
        this.filterEventFields(event, subscriptionNextPaymentCtaClickedFields),
      );
    },
  };

  subscriptionProduct = {
    sendPaywallModalOpenedEvent: (event: SubscriptionProductPaywallModalOpened): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_paywall_modal_opened',
        this.filterEventFields(event, subscriptionProductPaywallModalOpenedFields),
      );
    },
    sendCheckoutPayClickedEvent: (event: SubscriptionProductCheckoutPayClicked): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_checkout_pay_clicked',
        this.filterEventFields(event, subscriptionProductCheckoutPayClickedFields),
      );
    },
    sendLandingSignupFormViewEvent: (event: SubscriptionProductLandingSignupFormView): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_landing_signup_form_view',
        this.filterEventFields(event, subscriptionProductLandingSignupFormViewFields),
      );
    },
    sendCheckoutEmailSubmittedEvent: (event: SubscriptionProductCheckoutEmailSubmitted): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_checkout_email_submitted',
        this.filterEventFields(event, subscriptionProductCheckoutEmailSubmittedFields),
      );
    },
    sendPaywallModalClosedEvent: (event: SubscriptionProductPaywallModalClosed): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_paywall_modal_closed',
        this.filterEventFields(event, subscriptionProductPaywallModalClosedFields),
      );
    },
    sendSubscriptionPlanSelectedEvent: (event: SubscriptionProductSubscriptionPlanSelected): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_subscription_plan_selected',
        this.filterEventFields(event, subscriptionProductSubscriptionPlanSelectedFields),
      );
    },
    sendPurchaseCompletedEvent: (event: SubscriptionProductPurchaseCompleted): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_purchase_completed',
        this.filterEventFields(event, subscriptionProductPurchaseCompletedFields),
      );
    },
    sendTemporaryCredUserRedirectedToCheckoutEvent: (event: SubscriptionProductTemporaryCredUserRedirectedToCheckout): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_temporary_cred_user_redirected_to_checkout',
        this.filterEventFields(event, subscriptionProductTemporaryCredUserRedirectedToCheckoutFields),
      );
    },
    sendTemporaryUserEmailUpdatedEvent: (event: SubscriptionProductTemporaryUserEmailUpdated): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_temporary_user_email_updated',
        this.filterEventFields(event, subscriptionProductTemporaryUserEmailUpdatedFields),
      );
    },
    sendCheckoutVisitEvent: (event: SubscriptionProductCheckoutVisit): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_checkout_visit',
        this.filterEventFields(event, subscriptionProductCheckoutVisitFields),
      );
    },
    sendCheckoutErrorEvent: (event: SubscriptionProductCheckoutError): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_checkout_error',
        this.filterEventFields(event, subscriptionProductCheckoutErrorFields),
      );
    },
    sendCheckoutClosedEvent: (event: SubscriptionProductCheckoutClosed): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_checkout_closed',
        this.filterEventFields(event, subscriptionProductCheckoutClosedFields),
      );
    },
    sendUserCreatedEvent: (event: SubscriptionProductUserCreated): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_user_created',
        this.filterEventFields(event, subscriptionProductUserCreatedFields),
      );
    },
    sendPlatformPricingVisitEvent: (event: SubscriptionProductPlatformPricingVisit): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_platform_pricing_visit',
        this.filterEventFields(event, subscriptionProductPlatformPricingVisitFields),
      );
    },
    sendPricingClosedEvent: (event: SubscriptionProductPricingClosed): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_pricing_closed',
        this.filterEventFields(event, subscriptionProductPricingClosedFields),
      );
    },
    sendDesktopEmailScreenShownEvent: (event: SubscriptionProductDesktopEmailScreenShown): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_desktop_email_screen_shown',
        this.filterEventFields(event, subscriptionProductDesktopEmailScreenShownFields),
      );
    },
    sendDesktopEmailButtonClickedEvent: (event: SubscriptionProductDesktopEmailButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_desktop_email_button_clicked',
        this.filterEventFields(event, subscriptionProductDesktopEmailButtonClickedFields),
      );
    },
    sendRenewSubscriptionButtonClickedEvent: (event: SubscriptionProductRenewSubscriptionButtonClicked): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_renew_subscription_button_clicked',
        this.filterEventFields(event, subscriptionProductRenewSubscriptionButtonClickedFields),
      );
    },
    sendQuizQuestionCompletedEvent: (event: SubscriptionProductQuizQuestionCompleted): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_quiz_question_completed',
        this.filterEventFields(event, subscriptionProductQuizQuestionCompletedFields),
      );
    },
    sendExitOfferShownEvent: (event: SubscriptionProductExitOfferShown): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_exit_offer_shown',
        this.filterEventFields(event, subscriptionProductExitOfferShownFields),
      );
    },
    sendExitOfferClosedEvent: (event: SubscriptionProductExitOfferClosed): Promise<void> => {
      return this.client.sendEvent(
        'subscription_product_exit_offer_closed',
        this.filterEventFields(event, subscriptionProductExitOfferClosedFields),
      );
    },
  };

  taskReview = {
    sendTaskWasReviewedEvent: (event: TaskReviewTaskWasReviewed): Promise<void> => {
      return this.client.sendEvent(
        'task_review_task_was_reviewed',
        this.filterEventFields(event, taskReviewTaskWasReviewedFields),
      );
    },
    sendStartTaskReviewEvent: (event: TaskReviewStartTaskReview): Promise<void> => {
      return this.client.sendEvent(
        'task_review_start_task_review',
        this.filterEventFields(event, taskReviewStartTaskReviewFields),
      );
    },
    sendDeclinedByLinkAccessEvent: (event: TaskReviewDeclinedByLinkAccess): Promise<void> => {
      return this.client.sendEvent(
        'task_review_declined_by_link_access',
        this.filterEventFields(event, taskReviewDeclinedByLinkAccessFields),
      );
    },
  };

  teamProject = {
    sendJoinedTeamProjectEvent: (event: TeamProjectJoinedTeamProject): Promise<void> => {
      return this.client.sendEvent(
        'team_project_joined_team_project',
        this.filterEventFields(event, teamProjectJoinedTeamProjectFields),
      );
    },
    sendLeftTeamProjectEvent: (event: TeamProjectLeftTeamProject): Promise<void> => {
      return this.client.sendEvent(
        'team_project_left_team_project',
        this.filterEventFields(event, teamProjectLeftTeamProjectFields),
      );
    },
    sendStartedTeamProjectEvent: (event: TeamProjectStartedTeamProject): Promise<void> => {
      return this.client.sendEvent(
        'team_project_started_team_project',
        this.filterEventFields(event, teamProjectStartedTeamProjectFields),
      );
    },
    sendIntroPageOpenedEvent: (event: TeamProjectIntroPageOpened): Promise<void> => {
      return this.client.sendEvent(
        'team_project_intro_page_opened',
        this.filterEventFields(event, teamProjectIntroPageOpenedFields),
      );
    },
    sendIntroPageJoinBtnClickedEvent: (event: TeamProjectIntroPageJoinBtnClicked): Promise<void> => {
      return this.client.sendEvent(
        'team_project_intro_page_join_btn_clicked',
        this.filterEventFields(event, teamProjectIntroPageJoinBtnClickedFields),
      );
    },
    sendIntroPageLeaveBtnClickedEvent: (event: TeamProjectIntroPageLeaveBtnClicked): Promise<void> => {
      return this.client.sendEvent(
        'team_project_intro_page_leave_btn_clicked',
        this.filterEventFields(event, teamProjectIntroPageLeaveBtnClickedFields),
      );
    },
    sendIntroPageGeneralChatBtnClickedEvent: (event: TeamProjectIntroPageGeneralChatBtnClicked): Promise<void> => {
      return this.client.sendEvent(
        'team_project_intro_page_general_chat_btn_clicked',
        this.filterEventFields(event, teamProjectIntroPageGeneralChatBtnClickedFields),
      );
    },
    sendIntroPageGotoFirstTopicBtnClickedEvent: (event: TeamProjectIntroPageGotoFirstTopicBtnClicked): Promise<void> => {
      return this.client.sendEvent(
        'team_project_intro_page_goto_first_topic_btn_clicked',
        this.filterEventFields(event, teamProjectIntroPageGotoFirstTopicBtnClickedFields),
      );
    },
    sendIntroPageTeamChatBtnClickedEvent: (event: TeamProjectIntroPageTeamChatBtnClicked): Promise<void> => {
      return this.client.sendEvent(
        'team_project_intro_page_team_chat_btn_clicked',
        this.filterEventFields(event, teamProjectIntroPageTeamChatBtnClickedFields),
      );
    },
  };

  testTask = {
    sendFirstOpenedByStudentEvent: (event: TestTaskFirstOpenedByStudent): Promise<void> => {
      return this.client.sendEvent(
        'test_task_first_opened_by_student',
        this.filterEventFields(event, testTaskFirstOpenedByStudentFields),
      );
    },
    sendFirstSuccessfulCheckByMentorEvent: (event: TestTaskFirstSuccessfulCheckByMentor): Promise<void> => {
      return this.client.sendEvent(
        'test_task_first_successful_check_by_mentor',
        this.filterEventFields(event, testTaskFirstSuccessfulCheckByMentorFields),
      );
    },
  };

  testTaskActivity = {
    sendBannerClickedEvent: (event: TestTaskActivityBannerClicked): Promise<void> => {
      return this.client.sendEvent(
        'test_task_activity_banner_clicked',
        this.filterEventFields(event, testTaskActivityBannerClickedFields),
      );
    },
    sendSidebarUserProfileMiniatureClickedEvent: (event: TestTaskActivitySidebarUserProfileMiniatureClicked): Promise<void> => {
      return this.client.sendEvent(
        'test_task_activity_sidebar_user_profile_miniature_clicked',
        this.filterEventFields(event, testTaskActivitySidebarUserProfileMiniatureClickedFields),
      );
    },
    sendSidebarUserProfileDirectMessageClickedEvent: (event: TestTaskActivitySidebarUserProfileDirectMessageClicked): Promise<void> => {
      return this.client.sendEvent(
        'test_task_activity_sidebar_user_profile_direct_message_clicked',
        this.filterEventFields(event, testTaskActivitySidebarUserProfileDirectMessageClickedFields),
      );
    },
    sendSidebarSolvingNowSectionExpandedEvent: (event: TestTaskActivitySidebarSolvingNowSectionExpanded): Promise<void> => {
      return this.client.sendEvent(
        'test_task_activity_sidebar_solving_now_section_expanded',
        this.filterEventFields(event, testTaskActivitySidebarSolvingNowSectionExpandedFields),
      );
    },
    sendUserTaskSolutionOnboardingEvent: (event: TestTaskActivityUserTaskSolutionOnboarding): Promise<void> => {
      return this.client.sendEvent(
        'test_task_activity_user_task_solution_onboarding',
        this.filterEventFields(event, testTaskActivityUserTaskSolutionOnboardingFields),
      );
    },
    sendUserTaskSolutionOpenedEvent: (event: TestTaskActivityUserTaskSolutionOpened): Promise<void> => {
      return this.client.sendEvent(
        'test_task_activity_user_task_solution_opened',
        this.filterEventFields(event, testTaskActivityUserTaskSolutionOpenedFields),
      );
    },
  };

  timezone = {
    sendNewTimezoneAcceptedEvent: (event: TimezoneNewTimezoneAccepted): Promise<void> => {
      return this.client.sendEvent(
        'timezone_new_timezone_accepted',
        this.filterEventFields(event, timezoneNewTimezoneAcceptedFields),
      );
    },
    sendNewTimezoneModalClosedEvent: (event: TimezoneNewTimezoneModalClosed): Promise<void> => {
      return this.client.sendEvent(
        'timezone_new_timezone_modal_closed',
        this.filterEventFields(event, timezoneNewTimezoneModalClosedFields),
      );
    },
  };

  userProfile = {
    sendOpenedEvent: (event: UserProfileOpened): Promise<void> => {
      return this.client.sendEvent(
        'user_profile_opened',
        this.filterEventFields(event, userProfileOpenedFields),
      );
    },
    sendStatusUpdatedEvent: (event: UserProfileStatusUpdated): Promise<void> => {
      return this.client.sendEvent(
        'user_profile_status_updated',
        this.filterEventFields(event, userProfileStatusUpdatedFields),
      );
    },
    sendEditClickedEvent: (event: UserProfileEditClicked): Promise<void> => {
      return this.client.sendEvent(
        'user_profile_edit_clicked',
        this.filterEventFields(event, userProfileEditClickedFields),
      );
    },
    sendPrivacySettingUpdatedEvent: (event: UserProfilePrivacySettingUpdated): Promise<void> => {
      return this.client.sendEvent(
        'user_profile_privacy_setting_updated',
        this.filterEventFields(event, userProfilePrivacySettingUpdatedFields),
      );
    },
    sendUpdatedEvent: (event: UserProfileUpdated): Promise<void> => {
      return this.client.sendEvent(
        'user_profile_updated',
        this.filterEventFields(event, userProfileUpdatedFields),
      );
    },
  };

  webinar = {
    sendSignUpFormSubmittedEvent: (event: WebinarSignUpFormSubmitted): Promise<void> => {
      return this.client.sendEvent(
        'webinar_sign_up_form_submitted',
        this.filterEventFields(event, webinarSignUpFormSubmittedFields),
      );
    },
    sendRegistrationWithAnotherAccountClickedEvent: (event: WebinarRegistrationWithAnotherAccountClicked): Promise<void> => {
      return this.client.sendEvent(
        'webinar_registration_with_another_account_clicked',
        this.filterEventFields(event, webinarRegistrationWithAnotherAccountClickedFields),
      );
    },
  };

  constructor(client: AnalyticsClient) {
    this.client = client;
  }
}
