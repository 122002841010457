import { useEffect, useState } from 'react';
import {
  usePlatformTheme,
} from '@/components/common/ThemeContext/ThemeContext';

const getVariableName = (colorToken: string): string => colorToken
  .replace('var(', '')
  .replace(')', '');

const readColor = (colorToken: string): string | null => {
  // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
  if (typeof window === 'undefined') {
    return null;
  }

  const rootStyles = getComputedStyle(document.documentElement);

  return rootStyles.getPropertyValue(getVariableName(colorToken)).trim();
};

export const useColorByToken = (
  colorToken: string,
  defaultColor = '',
): string => {
  const { colorTheme } = usePlatformTheme();
  const [color, setColor] = useState<string>(
    () => readColor(colorToken) || defaultColor,
  );

  useEffect(() => {
    setColor(readColor(colorToken) || defaultColor);
  }, [colorTheme, colorToken, defaultColor]);

  return color;
};
