import {
  professionSlug,
  str,
  oneOf,
  int,
} from '@/controllers/router/type-safe-routes/parsers';
import { TopicSections } from '@/controllers/courseUserTopics/courseUserTopics.typedefs';

export const study = {
  path: ['study'],
  children: {
    autoRedirect: {
      path: ['auto-redirect'],
    },
    profession: {
      path: [professionSlug('professionSlug')],
      query: [str.optional('module')],
      children: {
        courseTopic: {
          path: [str('moduleSlug'), str('courseTopicSlug')],
          query: [
            oneOf(...Object.values(TopicSections)).optional('section'),
            str.optional('testTaskSlug'),
            int.optional('videoId'),
          ],
        },
      },
    },
  },
};
