import React, {
  DetailedHTMLProps, FC, InputHTMLAttributes, useCallback, useRef, useState,
} from 'react';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/src/utils/make-load-script-url';
import getConfig from 'next/config';
import { Async } from 'react-select/async';
import { useDI } from '@mate-academy/react-di';
import { UseFieldConfig } from 'react-final-form';
import { NoSSR } from '@/components/common/NoSSR';
import { cn } from '@/lib/classNames';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';
import { SelectOptionInterface } from '@/controllers/forms/forms.typedefs';
import formStyles from '@/components/ui/FormElements/FormInputs/FormInputs.module.scss';
import { SelectAsyncUi } from '@/components/ui/FormElements/FormInputs/Select/SelectAsync';
import { GoogleAddressInput } from '@/components/ui/FormElements/FormInputs/GoogleCitySelector/googleCitySelector.typedef';
import { nullFunction } from '@/lib/helpers/functional';
import { InputPlaceholder } from '@/components/ui/FormElements/FormInputs/InputPlaceholder';

export interface GoogleAddressInterface {
  googleCityId?: string;
  cityName?: string;
  regionName?: string;
  countryName?: string;
}

export interface GoogleCitySelectProps extends DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> {
  handleChange: (preparedCity: GoogleAddressInput) => void;
  handleOnLoad?: () => void;
  inputId: string;
  isMulti?: boolean;
  restrictionsCountry?: string[];
  isDisabled?: boolean;
}

const libraries: Libraries = ['places'];

const { publicRuntimeConfig = {} } = getConfig() || {};
const {
  GOOGLE_PLACES_API_KEY,
} = publicRuntimeConfig;

export const CitySelectorUi: FC<GoogleCitySelectProps> = (
  {
    className,
    handleChange,
    handleOnLoad,
    inputId,
    isMulti,
    restrictionsCountry = [],
    ...rest
  },
) => {
  const inputRef = useRef<Async<SelectOptionInterface> | null>(null);
  const preparedRestrictions = restrictionsCountry.filter((code) => code);

  const [
    autoCompleteInstance,
    setInstance,
  ] = useState<google.maps.places.Autocomplete | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_PLACES_API_KEY,
    language: 'en',
    libraries,
  });

  const onPlaceChanged = useCallback(() => {
    const city = autoCompleteInstance?.getPlace();

    if (city && city.place_id) {
      const countryInfo = city.address_components?.find(
        (component: { types: string[] }) => component.types.includes('country'),
      );

      const regionInfo = (city.address_components || []).find(
        (component: { types: string[] }) => component.types.includes('administrative_area_level_1'),
      );

      let countryName: string | undefined;
      let regionName: string | undefined;

      if (countryInfo) {
        countryName = countryInfo.long_name;
      }

      if (regionInfo) {
        regionName = regionInfo.long_name;
      }

      const preparedCity: GoogleAddressInput = {
        googleCityId: city.place_id,
        cityName: city.name,
        regionName,
        countryName,
      };

      handleChange(preparedCity);

      if (inputRef && inputRef.current) {
        inputRef.current.blur();
      }
    }
  }, [autoCompleteInstance, handleChange]);

  const onLoad = useCallback((
    instance: google.maps.places.Autocomplete,
  ) => {
    setInstance(instance);

    handleOnLoad?.();
  }, [handleOnLoad]);

  // ATTENTION: On CI we test mocked component before commit test your changes by running local e2e tests
  // INCIDENT: https://app.clickup.com/24383048/v/dc/q83j8-96295/q83j8-916135

  if (!isLoaded) {
    return <InputPlaceholder />;
  }

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      types={['(cities)']}
      restrictions={{ country: preparedRestrictions }}
      fields={['name', 'place_id', 'address_components']}
    >
      <SelectAsyncUi
        {...rest}
        isMulti={isMulti}
        className={cn(formStyles.googleCitySelect, className)}
        noOptionsMessage={nullFunction}
        components={{
          DropdownIndicator: nullFunction,
        }}
        ref={inputRef}
        inputId={inputId}
        allowCreateWhileLoading
      />
    </Autocomplete>
  );
};

export const CitySelector = withFinalFormController<
  SelectOptionInterface
>()<GoogleCitySelectProps>(CitySelectorUi);

type Props = GoogleCitySelectProps & {
  name: string;
  id?: string;
  config?: UseFieldConfig<SelectOptionInterface>;
};

const GoogleSelectorWithDI: FC<Props> = React.memo((props) => {
  const Select = useDI(CitySelector);

  return (
    <Select
      {...props}
    />
  );
});

export const GoogleCitySelector: FC<Props> = React.memo((props) => (
  <NoSSR>
    <GoogleSelectorWithDI {...props} />
  </NoSSR>
));
