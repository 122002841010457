import { ReadyApolloClient } from '@/controllers/apollo/apollo.typedefs';
import { CourseTopic } from '@/controllers/courseTopics/courseTopics.typedefs';
import { TopicStatus } from '@/controllers/graphql/generated';
import { TestTaskLinkFragment } from '@/components/platform/TestTasks/graphql/generated/testTaskLink.fragment.generated';

export interface TopicVideos {
  title: string;
  link: string;
}

export interface TopicMaterials {
  videos: TopicVideos[];
  theory: string;
}

export enum TopicSections {
  Practice = 'PRACTICE',
  Theory = 'THEORY',
  Video = 'VIDEO',
}

// TODO: avoid using TestTaskLinkFragment
export interface CourseUserTopic {
  id: number;
  courseTopic: CourseTopic;
  currentSection: TopicSections;
  testTaskLink: TestTaskLinkFragment;
  completed: boolean;
  completionRate: number;
  status: TopicStatus;
}

export type TopicPageQueryParams = {
  courseSlug: string;
  courseTopicSlug: string;
  section: string;
};

export interface CourseUserTopicOptions {
  apolloClient: ReadyApolloClient;
  userId: number;
  courseSlug: string;
  courseTopicSlug: string;
  lang: string;
}
